export function countAni() {
  const counters = document.querySelectorAll(".counter");

  for (let i = 0; i < counters.length; i++) {
    const targetNum = counters[i].getAttribute("data-count");
    let num = 0;
    const timer = setInterval(function () {
      if (Number(targetNum) === 0) {
        num = 0;
      } else {
        ++num;
      }
      counters[i].innerText = num;

      if (num === Number(targetNum)) {
        clearInterval(timer);
      }
    }, 50);
  }
}
