export function toggleHeader() {
    const gnb = document.getElementsByClassName("gnb");
    const gnbLi = document.querySelectorAll(".gnb li");

    for (var i = 0; i < gnbLi.length; i++) {
        gnbLi[i].addEventListener('mouseenter', (event) => {
            gnb[0].style.height = 'auto';
        });
        gnb[0].addEventListener('mouseleave', (event) => {
            gnb[0].style.height = '60px';
        });
    }
}