import React from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

function ResultContents() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    return (
        <section className="result clearfix">
            <div className="row-w">
                <div className="wrap-title">
                    <h1 className="tit serif-en">{t('my_tit')}
                        <span className="breadscrumb">
                            <Link to="/">{t('home')}</Link>
                            <Link to="/career/notice">{t('recruit')}</Link>
                            <Link to="#" className="on">{t('my_application')}</Link>
                        </span>
                    </h1>
                    {/* <h2 className="sub-tit" /> */}
                    <h3 className="sub-desc" dangerouslySetInnerHTML={{ __html: t('my_desc') }} />
                </div>
                <article className="apply-result">
                    <div className="wrap-result">
                        <h6 className="message">
                            <span className="color-yr">{state.email}</span>&nbsp;
                            <span dangerouslySetInnerHTML={{ __html: t('result_user_txt') }} />
                        </h6>
                        <ul className="list1 my mt34">
                            <li>
                                {Status(state.status, t)}
                            </li>
                        </ul>
                    </div>
                    <div className="wrap-btn mt40">
                        <button className="btn-tbl-form btn" onClick={()=>navigate('/career/my')}>{t('back')}</button>
                        <Link to="/main" className="btn btn-result"> {t('home')}</Link>
                    </div>
                </article>
            </div>
        </section>
    )
}
function Status(status, t) {
    let type = status * 1
    switch (type) {
        case 0:
            return (
                <dl>
                    <dd>
                        <img src="http://cdn.qijisoft.com/images/sub/career/apply-my-received-min.png" alt={t('review')} />
                    </dd>
                    <dt className="mt20" dangerouslySetInnerHTML={{ __html: t('result_review') }} />
                    <dd className="desc">{t('result_review_sub')}</dd>
                </dl>
            )
        case 1:
            return (
                <dl>
                    <dd>
                        <img src="http://cdn.qijisoft.com/images/sub/career/apply-my-pass-min.png" alt={t('pass')} />
                    </dd>
                    <dt className="mt20" dangerouslySetInnerHTML={{ __html: t('result_pass') }} />
                    <dd className="desc">{t('result_pass_sub')}</dd>
                </dl>
            )
        case 2:
            return (
                <dl>
                    <dd>
                        <img src="http://cdn.qijisoft.com/images/sub/career/apply-my-drop-min.png" alt={t('fail')} />
                    </dd>
                    <dt className="mt20" dangerouslySetInnerHTML={{ __html: t('result_fail') }} />
                    <dd className="desc">{t('result_fail_sub')}</dd>
                </dl>
            )
        default:
            return <></>
    }
}

export default ResultContents
//export default HistoryContents
