import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import langCn from "./lang.cn.json";
import langEn from "./lang.en.json";

const resource = {
  CN: {
    translation: langCn,
  },
  EN: {
    translation: langEn,
  },
};

i18n.use(initReactI18next).init({
  resources: resource,
  // 초기 설정 언어
  lng: "CN",
  fallbackLng: {
    CN: ["CN"],
    EN: ["EN"],
    default: ["CN"],
  },
  debug: false,
  defaultNS: "translation",
  ns: "translation",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
