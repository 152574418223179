import React, { useEffect, useState } from 'react'
// https://final-form.org/docs/react-final-form
import { Form, Field } from 'react-final-form'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import { API_CARERS, API_CARERS_APPLY, API_UPLOAD_FILE } from '../../api/qijisoft'
import { validName, validPhone, validEmail, validPwd, validRequired } from '../../utils/validate'
function ApplyContents() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const url_type = {
        resume_type: 'resume',
        work_type: 'work_type',
    }
    const { career_id } = useParams();
    const [career, setcareer] = useState([])
    const [resume_url, setresume_url] = useState('')
    const [resume_name, setresume_name] = useState('')
    const [work_name, setwork_name] = useState('')
    const [work_url, setwork_url] = useState('')
    const [isfile, setisfile] = useState(false)
    useEffect(() => {
        const getCareers = async () => {
            try {
                let res = await API_CARERS()
                if (res.code === 0) {
                    let data = res.data.filter(item => item.id === (career_id * 1))
                    setcareer(data)
                } else {
                    alert(JSON.stringify(res.data))
                }
            } catch (error) {
                console.log(error)
            }
        }
        getCareers()
        return () => {

        }
    },[career_id])
    // 上传简历
    const ReadFiles = async (file, type) => {
        setisfile(true)
        let maxSize;
        let sizetip = ''
        if (type === url_type.resume_type) {
            maxSize = 1024 * 1024 * 5
            sizetip = '5MB'
        }
        if (type === url_type.work_type) {
            maxSize = 1024 * 1024 * 20
            sizetip = '20MB'
        }
        if (file.target.files[0] && file.target.files[0].size > maxSize) {
            return alert(`上传文件不能超过${sizetip}`)
        }
        const param = new FormData();
        param.append("file", file.target.files[0]);
        try {
            let filedata = await API_UPLOAD_FILE(param)
            if (filedata.code === 0) {
                if (type === url_type.resume_type) {
                    setresume_name(file.target.files[0].name)
                    setresume_url(filedata.data.url)
                }
                if (type === url_type.work_type) {
                    setwork_name(file.target.files[0].name)
                    setwork_url(filedata.data.url)
                }
            } else {
                alert(JSON.stringify(filedata.data))
            }
        } catch (error) {
            alert('网络错误, 请稍后重试!')
        }
    }
    // 提交表单
    const onSubmit = async values => {
        console.log(isfile, 'isfile')
        const json = JSON.parse(JSON.stringify(values))
        json.policy = json.policy ? 1 : 0
        json.career_id = career_id
        if (resume_url) {
            json.resume_url = resume_url
        } else {
            // 没有上传简历不进行提交
            return
        }
        if (work_url) {
            json.work_url = work_url
        }
        try {
            let res = await API_CARERS_APPLY(json)
            if (res.code === 0) {
                alert('提交成功！')
                navigate('/career/my')
            } else {
                if (res.data.email[0]) {
                    alert(res.data.email[0])
                } else {
                    alert(JSON.stringify(res.data))
                }
            }
        } catch (error) {
            alert('网络错误，请稍后重试!')
        }
    }
    const composeValidators = (...validators) => value =>
        validators.reduce((error, validator) => error || validator(value), undefined)
    if (career.length) {
        return (
            <section className="apply clearfix">
                <div className="row-w">
                    <div className="wrap-title">
                        <h1 className="tit serif-en">{t('apply_tit')}
                            <span className="breadscrumb">
                                <Link to="/">{t('home')}</Link>
                                <Link to="/career/notice">{t('recruit')}</Link>
                                <Link to="#" className="on">{t('apply')}</Link>
                            </span>
                        </h1>
                        {/* <h2 className="sub-tit">{t('apply_subtit')}</h2> */}
                        <h3 className="sub-desc" dangerouslySetInnerHTML={{ __html: t('apply_desc') }} />
                    </div>
                    <article className="apply-form">
                        <Form
                            onSubmit={onSubmit}
                            validate={values => {
                                const errors = {}
                                //  输入16字以内，包括英文、数字、特殊字符
                                if (!values.password) {
                                    errors.password = 'error'
                                } else if (validPwd(values.password)) {
                                    errors.password = 'error'
                                }
                                if (!values.password_confirmation) {
                                    errors.password_confirmation = 'error'
                                } else if (values.password_confirmation !== values.password) {
                                    errors.password_confirmation = 'error'
                                }
                                return errors
                            }}
                            render={({ handleSubmit, submitting }) => (
                                <form name="frm" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
                                    <fieldset>
                                        <legend className="hidden">{t('apply_form')}</legend>
                                        <div className="tbl-style">
                                            {/* 직업선택 */}
                                            <div className="tbl-row clearfix">
                                                <div className="row1 clearfix">
                                                    <label htmlFor="cate" className="show">{t('form_apply_position')}</label>
                                                    <div className="wrap-input">
                                                        <input type="text" name="" id="cate" value={`${career[0].cate.name}（${career[0].position.name}）`} disabled />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* 지원자 성명 */}
                                            <div className="tbl-row clearfix">
                                                <div className="row1 clearfix">
                                                    <label htmlFor="name" className="show">{t('form_applicant_name')}</label>
                                                    <Field name="name" validate={composeValidators(validRequired, validName)}>
                                                        {({ input, meta }) => (
                                                            <div className="wrap-input">
                                                                <input type="text" {...input} id="pName" placeholder={[t('form_applicant_name'), t('form_input')]} />
                                                                {
                                                                    meta.error && meta.touched ? <><span className="fa fa-exclamation-circle color-r" />必须是汉字 且最少两个汉字，最多不超过五个</> :
                                                                        meta.touched ? <><span className="fa fa-check-circle color-green" />&nbsp;{t('form_complete')}</> : <></>
                                                                }
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                            {/* 비밀번호 + 비밀번호 확인 */}
                                            <div className="tbl-row clearfix">
                                                <div className="row3">
                                                    <label htmlFor="password" className="show"> {t('form_password')}</label>
                                                    <Field name="password">
                                                        {({ input, meta }) => (
                                                            <div className="wrap-input">
                                                                <input type="password" {...input} id="pw1" placeholder={t('form_pw_regulation')} title={t('form_pw_regulation')} />
                                                                {
                                                                    meta.error && meta.touched ? <><span className="fa fa-exclamation-circle color-r" />{t('form_pw_regulation')}</> :
                                                                        meta.touched ? <><span className="fa fa-check-circle color-green" />&nbsp;{t('form_complete')}</> : <></>
                                                                }
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                                <div className="row3">
                                                    <label htmlFor="password_confirmation" className="show">{t('form_repassword')}</label>
                                                    <Field name="password_confirmation">
                                                        {({ input, meta }) => (
                                                            <div className="wrap-input">
                                                                <input type="password" {...input} id="pw2" placeholder={t('form_reenter')} />
                                                                {
                                                                    meta.error && meta.touched ? <><span className="fa fa-exclamation-circle color-r" />{t('form_pw_notmatch')}</> :
                                                                        meta.touched ? <><span className="fa fa-check-circle color-green" />&nbsp;{t('form_complete')}</> : <></>
                                                                }
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>

                                            {/* 연락처 */}
                                            <div className="tbl-row clearfix">
                                                <div className="row2">
                                                    <label htmlFor="phone" className="show"> {t('cellphone')}</label>
                                                    <Field name="phone" validate={composeValidators(validRequired, validPhone)}>
                                                        {({ input, meta }) => (
                                                            <div className="wrap-input">
                                                                <input type="tel" {...input} name="phone" id="phone" className="int-tel" placeholder={t('form_required')} />
                                                                {
                                                                    meta.error && meta.touched ? <><span className="fa fa-exclamation-circle color-r" />{t('cellphone')} 格式错误</> :
                                                                        meta.touched ? <><span className="fa fa-check-circle color-green" />&nbsp;{t('form_complete')}</> : <></>
                                                                }
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                            {/* 이메일 */}
                                            <div className="tbl-row clearfix">
                                                <div className="row1">
                                                    <label htmlFor="email" className="show">{t('email')}</label>
                                                    <Field name="email" validate={composeValidators(validRequired, validEmail)}>
                                                        {({ input, meta }) => (
                                                            <div className="wrap-input">
                                                                <input type="email" {...input} name="email" id="email" placeholder={t('form_required')} />
                                                                {
                                                                    meta.error && meta.touched ? <><span className="fa fa-exclamation-circle color-r" />{t('form_wrong_email')}</> :
                                                                        meta.touched ? <><span className="fa fa-check-circle color-green" />&nbsp;{t('form_complete')}</> : <></>
                                                                }
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                            {/* 이력서 */}
                                            <div className="tbl-row clearfix">
                                                <div className="row1">
                                                    <label htmlFor="file1" className="show">{t('resume')}</label>
                                                    <div className="file-apply">
                                                        <input type="file" name='resume_url' id="file1" className="inputfile inputfile-2" onChange={(e) => ReadFiles(e, url_type.resume_type)} accept=".pdf, .hwp, .docx" />
                                                        <label htmlFor="file1" className="file-name-label">
                                                            <span className="file-name">{resume_url !== '' ? resume_name : `${t('cant_exceed_5mb')} 格式：${t('pdf_docx_hwp')}`}</span>
                                                            <strong>
                                                                <span className="fa fa-upload" />&nbsp;
                                                                <span className="text">{t('file')}</span>
                                                            </strong>
                                                            
                                                        </label>
                                                        {
                                                            resume_url === '' && isfile ? <span className="input-alert on unchk"><span className="fa fa-exclamation-circle color-r" />{t('form_wrong_file')}{t('pdf_docx_hwp')}</span> :
                                                                isfile ? <span className="input-alert on chk"><span className="fa fa-check-circle color-green" />&nbsp;{t('form_complete')}</span> : <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {/* 경력서 ? */}
                                            <div className="tbl-row clearfix">
                                                <div className="row1">
                                                    <label htmlFor="file1" className="show">{t('portfolio')}</label>
                                                    <div className="file-apply">
                                                        <input type="file" name="file2" id="file2" className="inputfile inputfile-2" onChange={(e) => ReadFiles(e, url_type.work_type)} accept=".jpg, .pdf, .ppt" />
                                                        <label htmlFor="file2" className="file-name-label">
                                                            <span
                                                                className="file-name">{work_url !== '' ? work_name : `${t('portfolio_desc')} 格式：${t('jpg_pdf_ppt')}`}</span>
                                                            <strong>
                                                                <span className="fa fa-upload" />&nbsp;
                                                                <span className="text">{t('file')}</span>
                                                            </strong>
                                                        </label>
                                                        <span className="input-alert unchk"><span className="fa fa-exclamation-circle color-r" />&nbsp;{t('form_wrong_file')}{t('jpg_pdf_ppt')}</span>
                                                        <span className="input-alert chk"><span className="fa fa-check-circle color-green" />&nbsp;{t('form_complete')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        { /*START - 개인정보수집동의 */}
                                        <div className="tbl-style">
                                            <div className="tbl-row agree clearfix">
                                                <div className="row0 clearfix">
                                                    <span className="show">{t('proposal_term_txt1')}</span>
                                                    <div className="wrap-input agree">
                                                        <label className="right">
                                                            <Field name="policy" component="input" type="checkbox" id="privacyChk" validate={validRequired} />
                                                            <span>{t('proposal_term_txt2')} </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="row0 clearfix">
                                                    <div className="wrap-input-txt clearfix">
                                                        {/* textarea 안에는 dangerouslySetInnerHTML을 사용할 수 없음... lang을 나눌수도 없음 하나의 child만 가진다고 함 */}
                                                        <div className="textarea privacy-collect" dangerouslySetInnerHTML={{ __html: t('proposal_term_txt3') }} /> {/*contentEditable='true'*/}
                                                        <br />
                                                        <Error name="policy" t={t} text={t('proposal_term_txt4')} />
                                                        {/* <span className="input-alert unchk" id="privacyChk-error">
                                                                    <span className="fa fa-exclamation-circle color-r" /> {t('proposal_term_txt4')}
                                                                </span>
                                                                <span className="input-alert chk" id="privacyChk-success">
                                                                    <span className="fa fa-check-circle color-green" />&nbsp;{t('form_complete')}
                                                                </span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="wrap-btn tbl">
                                            <button className="btn-tbl-form btn" onClick={() => window.history.go(-1)}>{t('cancel')}</button>
                                            <button className="btn-tbl-form btn" type="submit" disabled={submitting} onClick={()=> setisfile(true)}>{t('confirm')}</button>
                                        </div>
                                    </fieldset>
                                </form>
                            )}></Form>

                    </article>
                </div>
            </section >
        )
    } else {
        return <></>
    }
}

const Error = ({ name, t, text }) => (<Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) => (
        error && touched ? <><span className="fa fa-exclamation-circle color-r" />&nbsp;{text}</> :
            touched ? <><span className="fa fa-check-circle color-green" />&nbsp;{t('form_complete')}</> : <></>
    )
    }
/>)
export default ApplyContents
//export default HistoryContents
