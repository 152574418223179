import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import MainBanner from "./MainBanner";
import moment from 'moment'

import './mainContents.scoped.scss'

import { API_BANNERS, API_CATES_CAREERS, API_CARERS } from '../../api/qijisoft'
class MainContents extends React.Component {

    state = {
        banners: [],
        CatesCareers: [],
        careerNews: [],
        careerHots: []
    }
    componentDidMount() {
        //document.title = HTML_TITLE_PREFIX + '-메인'
        //fnReCanvas.drawRecruitCanvas();
        this.getBanners()
        this.getCatesCareers()
        this.getCareers('new')
        this.getCareers('hot')
    }

    getBanners = async () => {
        try {
            let res = await API_BANNERS()
            if (res.code === 0) {
                this.setState({
                    banners: res.data
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    getCatesCareers = async () => {
        try {
            let res = await API_CATES_CAREERS()
            if (res.code === 0) {
                this.setState({ CatesCareers: res.data })
            } else {
                alert(JSON.stringify(res.data))
            }
        } catch (error) {
            alert('网络错误, 请稍后重试!')
        }
    }
    getCareers = async (type) => {
        try {
            if (type === 'new') {
                let res = await API_CARERS({ order: 'recent' })
                if (res.code === 0) {
                    let careerNews10 = res.data.filter((t, i) => (i < 6))
                    this.setState({
                        careerNews: careerNews10
                    })
                }
            }
            if (type === 'hot') {
                let res = await API_CARERS()
                if (res.code === 0) {
                    let careerHots10 = res.data.filter((t, i) => (i < 6))
                    this.setState({
                        careerHots: careerHots10
                    })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    format = (date) => {
        return moment(date).format('YYYY-M-D')
    }
    render() {
        const { t } = this.props;
        const { banners, CatesCareers, careerNews, careerHots } = this.state
        return (
            <>
                <MainBanner banners={banners} />
                <section style={{ display: 'none' }} className="whatwedo-new">
                    <div className="row-w clearfix">
                        <h1 className="tit">
                            关于我们
                        </h1>
                        <h2 className="sub-tit">We always think about the needs and values of users.</h2>
                        <div className="cont">
                            <ul>
                                <li>
                                    <span className="img clearfix"></span>
                                    <span className="line clearfix"></span>
                                    <dl>
                                        <dt>
                                            <span>Game Publishing</span>
                                        </dt>
                                        <dd className="">
                                        </dd>
                                    </dl>
                                    <a href="/company/intro" className="btn-more"><span>More</span> + </a>
                                </li>
                                <li>
                                    <span className="img clearfix"></span>
                                    <span className="line clearfix"></span>
                                    <dl>
                                        <dt>
                                            <span>Game Developing</span>
                                        </dt>
                                        <dd className="">
                                        </dd>
                                    </dl>
                                    <a href="/company/intro" className="btn-more"><span>More</span> + </a>
                                </li>
                                <li>
                                    <span className="img clearfix"></span>
                                    <span className="line clearfix"></span>
                                    <dl>
                                        <dt>
                                            <span>Game Operating</span>
                                        </dt>
                                        <dd className="">

                                        </dd>
                                    </dl>
                                    <a href="/company/intro" className="btn-more"><span>More</span> + </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="whatwedo bg-l-grey">
                    <div className="row-w clearfix">
                        <h1 className="tit">
                            <img src="//cdn.qijisoft.com/miracle/images/logo-big.png" alt="miracle logo" />
                            {t('what_we_do')}
                        </h1>
                        <article className="cont">
                            <ul>
                                <li>
                                    <Link to="/company/intro">
                                        <span className="img clearfix"></span>
                                        <span className="line clearfix"></span>
                                        <dl>
                                            <dt dangerouslySetInnerHTML={{ __html: t('pc_game') }} />
                                            <dd dangerouslySetInnerHTML={{ __html: t('what_txt1') }} />
                                        </dl>
                                    </Link>
                                </li>
                                <li>
                                    <a href="/company/intro">
                                        <span className="img clearfix"></span>
                                        <span className="line clearfix"></span>
                                        <dl>
                                            <dt dangerouslySetInnerHTML={{ __html: t('mb_game') }} />
                                            <dd dangerouslySetInnerHTML={{ __html: t('what_txt2') }} />
                                        </dl>
                                    </a>
                                </li>
                                <li>
                                    <a href="/company/intro">
                                        <span className="img clearfix"></span>
                                        <span className="line clearfix"></span>
                                        <dl>
                                            <dt dangerouslySetInnerHTML={{ __html: t('other_game') }} />
                                            <dd className="">
                                                {t('what_txt3')}
                                            </dd>
                                        </dl>
                                    </a>
                                </li>
                            </ul>
                        </article>
                    </div>
                </section>
                <section className="recruit clearfix">
                    <div className="row-w clearfix">
                        <div className="title">
                            <h1 className="tit left clearfix">{t('career')}
                                <Link to="/career/notice" className="btn-more">{t('more_en')} <img src="//cdn.qijisoft.com/miracle/images/main/icon-chevron-right.png" alt=">" /> </Link>
                            </h1>
                        </div>
                        <article className="cont">
                            <ul className="clearfix">
                                {
                                    CatesCareers.map(item => {
                                        return (
                                            <li key={item.id}>
                                                <Link to={`/career/notice?cate_id=${item.id}`}>
                                                    <dl className="text">
                                                        <dt>{item.name}</dt>
                                                        <dd>
                                                            {/* <span className="cate-num"> 7 </span> */}
                                                            {/* {t('ea')} {t('category')} <br /> */}
                                                            {t('career_hiring_on')}
                                                            <span className="vacant-num"> {item.careers_count} {t('case')}</span>
                                                        </dd>
                                                    </dl>
                                                    <div className="img-wrap">
                                                        {
                                                            Jobicon(item.id)
                                                        }
                                                        {/* <img src="//cdn.qijisoft.com/miracle/images/main/icon-job-programmer.png"
                                                            alt="Job Vacancy - Software Engineering" /> */}
                                                    </div>
                                                    <span className="btn-job-arr"></span>
                                                </Link>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </article>
                    </div>
                </section>
                <section className="hot-position">
                    <div className="row-w clearfix">
                        <div className="title">
                            <h1 className="tit left clearfix">{t('hot_position')}</h1>
                        </div>
                        <article className="cont clearfix">
                            <div className="latest pull-left">
                                <h2 className="sub-tit">{t('latest_job')}
                                    <Link to="/career/notice" className="common-btn btn-more" >{t('more')}</Link>
                                </h2>
                                <ul className="latest">
                                    {
                                        careerNews.map(item => {
                                            return (
                                                <li key={item.id} className="clearfix">
                                                    <Link to={`/career/detail/${item.id}`} className="ellipsis">{item.position.name}</Link>
                                                    <span className="date">{this.format(item.updated_at)}</span>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="popular pull-right">
                                <h2 className="sub-tit">{t('popular_job')}
                                    <Link to="/career/notice" className="common-btn btn-more">{t('more')} </Link>
                                </h2>
                                <ul className="popular">
                                    {
                                        careerHots.map(item => {
                                            return (
                                                <li key={item.id} className="clearfix">
                                                    <Link to={`/career/detail/${item.id}`} className="ellipsis">{item.position.name}</Link>
                                                    <span className="date">{this.format(item.updated_at)}</span>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </article>
                    </div>
                </section>
                <section>
                    <div className="row-w">
                        <div className="type-jobs-wrap clearfix">
                            <div className="type-write">
                                <span className="line-1 anim-typewriter">和我们一起开始你的事业吧 :) &nbsp;&nbsp;&nbsp;
                                    <span className="fa fa-angle-right"></span>
                                    <span className="fa fa-angle-right"></span>
                                    <span className="fa fa-angle-right"></span>
                                </span>
                            </div>
                            <div className="join-bar-en">
                                <div className="job-key"></div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="partner">
                    <div className="row-w clearfix">
                        <div className="title">
                            <h1 className="tit left clearfix">{t('dev_proposal')}</h1>
                        </div>
                        <article>
                            <p>{t('dev_proposal_desc')}</p>
                            <div className="wrap-btn">
                                <a href="/company/proposal" className="common-btn btn-partner">{t('do_proposal')}</a>
                            </div>
                            <ul className="clearfix">
                                <li>
                                    <dl>
                                        <dt><img src="//cdn.qijisoft.com/miracle/images/main/partner1.png" alt="撰写开发提案书" /></dt>
                                        <dd>{t('proposal_txt1')}</dd>
                                    </dl>
                                </li>
                                <li>
                                    <dl>
                                        <dt><img src="//cdn.qijisoft.com/miracle/images/main/partner3.png" alt="协调合同细节" /></dt>
                                        <dd>{t('proposal_txt2')}</dd>
                                    </dl>
                                </li>
                                <li>
                                    <dl>
                                        <dt><img src="//cdn.qijisoft.com/miracle/images/main/partner4.png" alt="研究开发提案书" /></dt>
                                        <dd>{t('proposal_txt3')}</dd>
                                    </dl>
                                </li>
                            </ul>
                        </article>
                    </div>
                </section>
            </>
        )
    }
}
function Jobicon(id) {
    let type = id * 1
    switch (type) {
        case 1:
            return (<img src="http://cdn.qijisoft.com/images/main/icon-job-programmer-min.png" alt="Job Vacancy - Software Engineering" />)
        case 2:
            return (<img src="//cdn.qijisoft.com/miracle/images/main/icon-job-technology.png"
                alt="Job Vacancy - Software Engineering" />)
        case 3:
            return (<img src="//cdn.qijisoft.com/miracle/images/main/icon-job-planning.png"
                alt="Job Vacancy - Software Engineering" />)
        case 4:
            return (<img src="//cdn.qijisoft.com/miracle/images/main/icon-job-art.png"
                alt="Job Vacancy - Software Engineering" />)
        case 5:
            return (<img src="//cdn.qijisoft.com/miracle/images/main/icon-job-design.png"
                alt="Job Vacancy - Software Engineering" />)
        case 6:
            return (<img src="//cdn.qijisoft.com/miracle/images/main/icon-job-administration.png"
                alt="Job Vacancy - Software Engineering" />)
        default:
            return (<img src="//cdn.qijisoft.com/miracle/images/main/icon-job-technology.png"
                alt="Job Vacancy - Software Engineering" />)
    }
}
export default withTranslation()(MainContents)
//export default MainContents
