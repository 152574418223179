
// 名字
export function validName(val) {
    const reg = /^[\u4e00-\u9fa5]{2,5}$/
    return !reg.test(val)
}
// 电话
export function validPhone(val) {
    const reg = /^(0[0-9])\d{9}$/ // 02187654321
    const reg1 = /^(0[0-9]{2,3}-)([2-9][0-9]{6,7})+(-[0-9]{1,4})?$/ // 021-87654321
    const reg2 = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/ // 手机号
    if(reg.test(val)){
        return undefined
    }
    if(reg1.test(val)){
        return undefined
    }
    if(reg2.test(val)) {
        return undefined
    }
    return 'error'
}
// 邮箱
export function validEmail(val) {
    const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    return !reg.test(val)
}
// 密码
export function validPwd(val) {
    const reg = /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z]).*$/
    // const reg = /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/
    return !reg.test(val)
}
// 空判断
export function validRequired(val) {
    return val ? undefined : 'error'
}