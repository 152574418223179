import ReactGA from "react-ga";

export const initGA = (trackingID) => {
    ReactGA.initialize(trackingID);
}

ReactGA.pageview(window.location.pathname + window.location.search);


export const PageView = () => {
    ReactGA.pageview(window.location.pathname +
        window.location.search);
}

/**
 * Event - Add custom tracking event.
 * //@param {string} window
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const Event = (category, action, label) => {
    ReactGA.event({
        //clientWindow: window,
        category: category,
        action: action,
        label: label
    });
};