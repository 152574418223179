/**
 * 查询首页banner列表
 * 请求类型 get
*/
export const GET_API_BANNERS = '/api/banners'
/**
 * 查询游戏列表
 * 请求类型 get
*/
export const GET_API_GAMES = '/api/games'
/**
 * 查询招聘列表
 * 请求类型 get
*/
export const GET_API_CARERS = '/api/careers'
/**
 * 应聘申请
 * 请求类型 post
*/
export const POST_API_CARERS_APPLY = '/api/careers/apply'
/**
 * 查询应聘状态
 * 请求类型 post
*/
export const POST_API_CARERS_MY = '/api/careers/my'
/**
 * 查询岗位详情
 * 请求类型 get
*/
export const GET_API_CARERS_DETAIL = '/api/careers/'
/**
 * 查询开发组列表
 * 请求类型 get
*/
export const GET_API_TEAMS_CAREERS = '/api/teams/careers'
/**
 * 查询分类招聘信息
 * 请求类型 get
*/
export const GET_API_CATES_CAREERS = '/api/cates/careers'

/**
 * 查询团队列表
 * 请求类型 get
*/
export const GET_API_TEAMS = '/api/teams'
/**
 * 上传附件
 * 请求类型 post
*/
export const POST_API_UPLOAD_FILE = '/api/upload/file'
/**
 * 合作建议
 * 请求类型 post
*/
export const POST_API_PROPOSAL = '/api/proposal'

/**
 * 合作类别
 * 请求类型 get
*/
export const GET_API_PROPOSAL_TYPE = '/api/proposal/type'

/**
 * 岗位分类 - 带细分岗位接口
 * 请求类型 get
*/
export const GET_API_CATES_POSITIONS = '/api/cates/positions'
/**
 * 获取公司数量信息
 * 请求类型 get
*/
export const GET_API_COMPANY_INFO = '/api/company/info'