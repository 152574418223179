import React from 'react'
import { Link } from 'react-router-dom'
//import '../../asset/css/sub.css'

import { withTranslation } from 'react-i18next';

class HistoryContents extends React.Component {
    // 클릭하는 것에 on 붙고
    // 메뉴 li의 id와 같은 class명을 가진 tbody가 보인다..
    constructor(props, context) {
        super();

        this.state = {
            tab_menu: 0,
        }
    }
    historyTab = (menuIndex) => {
        this.setState({ menu: menuIndex });
    }
    componentDidMount() {

    }

    render() {
        const { t } = this.props;
        return (
            <>
                <section className="history clearfix">
                    <div className="row-w">
                        <div className="wrap-title">
                            <h1 className="tit serif-en">{t('history_tit')}
                                <span className="breadscrumb">
                                    <Link to="/">{t('home')}</Link>
                                    <Link to="/company/intro">{t('intro')}</Link>
                                    <Link to="#" className="on">{t('history')}</Link>
                                </span>
                            </h1>
                            {/* <h2 className="sub-tit" ></h2> */}
                            <h3 className="sub-desc" dangerouslySetInnerHTML={{ __html: t('history_subtit') }} />
                        </div>
                        <article className="history-table">
                            <div className="row-inner">
                                {/*<div className="history-tab mt60">
                                    <ul>
                                        <li><Link id="year15" className={`${this.state.menu === 0? 'on': ''}`} to="#" onClick={() => this.historyTab(0)} >2021 ~ 2018</Link></li>
                                        <li><Link id="year11" to="javascript:void(0);">2017 ~ 2014</Link></li>
                                        <li><Link id="year7" to="javascript:void(0);">2013 ~ 2011</Link></li>
                                        <li><Link id="year3" to="javascript:void(0);">2010 ~ </Link></li>
                                    </ul>
                                </div>
                                {this.state.menu === 0 ? <div className="table tab2021">예시입니다. TEST</div> : null}*/}
                                <table className="no-side-border" id="history">
                                    <colgroup>
                                        <col width="15%" />
                                        <col width="10%" />
                                        <col width="*" />
                                    </colgroup>
                                    <tbody className="year3">
                                        {/* 2021 */}
                                        <tr>
                                            <th rowSpan="5">2021</th>
                                            <td className="text-center">9</td>
                                            <td colSpan="3"><span className="color-green">《C9》</span>&nbsp;国际服迁移服务
                                                <a rel="noreferrer" href="https://transfer.valofe.com/c9/transfer" target="_blank"> ▷ https://transfer.valofe.com/c9/transfer</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">7</td>
                                            <td colSpan="3"><span className="color-green"> 《C9》</span>&nbsp;开启技术开发服务
                                                <a rel="noreferrer" href="https://c9-gl.valofe.com" target="_blank"> ▷ https://c9-gl.valofe.com</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">6</td>
                                            <td colSpan="3"><span className="color-green">《诸神国度》</span>&nbsp;开启服务
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">4</td>
                                            <td colSpan="3"><span className="color-green">《风林火山》</span>&nbsp;韩服开启服务
                                                <a rel="noreferrer" href="https://wffm.valofe.com/" target="_blank"> ▷ https://wffm.valofe.com/</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">2</td>
                                            <td colSpan="3"><span className="color-green">《风林火山》</span>&nbsp;风林火山韩服事前迁移
                                                <a rel="noreferrer" href="http://transfer-ko.valofe.com/wffm/transfer" target="_blank"> ▷ http://transfer-ko.valofe.com/wffm/transfer</a>
                                            </td>
                                        </tr>
                                        {/* 2020 */}
                                        <tr>
                                            <th rowSpan="4">2020</th>
                                            <td className="text-center">11</td>
                                            <td colSpan="3"><span className="color-green">《MU Legend》</span>&nbsp;MU Legend Global GameSworld, 合同签约及开启服务
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">11</td>
                                            <td colSpan="3"><span className="color-green">《MU Legend》</span>&nbsp;MU Legend Asia Line POD 开启服务
                                                <a rel="noreferrer" href="https://linepodth.com/mu-legend/" target="_blank"> ▷ https://linepodth.com/mu-legend/</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">7</td>
                                            <td colSpan="3"><span className="color-green">《Combat Arms: the Classic》</span>&nbsp;Combat Arms the Classic Global Steam 开启服务
                                                <a rel="noreferrer" href="https://store.steampowered.com/app/1263550/Combat_Arms_the_Classic/" target="_blank"> ▷ https://store.steampowered.com/app/1263550/Combat_Arms_the_Classic/</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">4</td>
                                            <td colSpan="3"><span className="color-green">《N-AGE》</span>&nbsp; 韩国合同签约及开启服务
                                                <a rel="noreferrer" href="http://nage-ko.valofe.com/" target="_blank"> ▷ http://nage-ko.valofe.com/</a>
                                            </td>
                                        </tr>
                                        {/* 2019 */}
                                        <tr>
                                            <th rowSpan="5">2019</th>
                                            <td className="text-center">11</td>
                                            <td colSpan="3"><span className="color-green">《MU Legend》</span>&nbsp;开启韩国、全球、日本服务
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">11</td>
                                            <td colSpan="3"><span className="color-green">《MU Legend》</span>&nbsp;GL, Steam 开启服务
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">11</td>
                                            <td colSpan="3"><span className="color-green">《MU Legend》</span>&nbsp;KR, 开启服务</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">10</td>
                                            <td colSpan="3"><span className="color-green">《MU Legend》</span>&nbsp;日服开启服务</td>
                                        </tr>

                                        <tr>
                                            <td className="text-center">9</td>
                                            <td colSpan="3"><span className="color-green">《MU Legend》</span>&nbsp;韩服事前迁移
                                                <a rel="noreferrer" href="https://mulegend.valofe.com" target="_blank"> ▷ https://mulegend.valofe.com</a>
                                            </td>
                                        </tr>
                                        {/* 2018 */}
                                        <tr>
                                            <th rowSpan="6">2018</th>
                                            <td className="text-center">11</td>
                                            <td colSpan="3"><span className="color-green">《Combat Arms:Classic》</span>&nbsp;LATAM 上线
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">6</td>
                                            <td colSpan="3"><span className="color-green">《FANTASY WAR TACTICS R》</span>&nbsp;韩国、北美开启服务
                                                <a rel="noreferrer" href="https://fwtr.valofe.com" target="_blank"> ▷ https://fwtr.valofe.com</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">5</td>
                                            <td colSpan="3"><span className="color-green">《Legion of Heroes》</span>&nbsp;韩国、北美开启服务
                                                <a rel="noreferrer" href="https://yg.valofe.com" target="_blank">  ▷ https://yg.valofe.com</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">5</td>
                                            <td colSpan="3"><span className="color-green">《Kritika》</span>&nbsp;日服开启技术开发服务
                                                <a rel="noreferrer" href="http://www.kritika.jp" target="_blank">  ▷ http://www.kritika.jp</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">5</td>
                                            <td colSpan="3"><span className="color-green">《Legion of Heroes(Mobile)》,《Fantasy War TacticsR (Mobile)》</span>&nbsp;获得开发及服务权
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">1</td>
                                            <td colSpan="3"><span className="color-green">《Combat Arms: the Classic》</span>&nbsp;北美、欧洲开启服务
                                                <a rel="noreferrer" href="https://combatarms-c.valofe.com" target="_blank">  ▷ https://combatarms-c.valofe.com</a>
                                            </td>
                                        </tr>
                                        {/* 2017 */}
                                        <tr>
                                            <th rowSpan="2">2017</th>
                                            <td className="text-center">5</td>
                                            <td colSpan="3"><span className="color-green">《Angel Stone》</span>&nbsp;国服开启技术开发服务
                                                <a rel="noreferrer" href="http://tszs.xiyouxi.com" target="_blank"> ▷ http://tszs.xiyouxi.com</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">2</td>
                                            <td colSpan="3"><span className="color-green">《KRITIKA》</span>&nbsp;日服开启技术开发服务
                                            </td>
                                        </tr>
                                        {/* 2015 */}
                                        <tr>
                                            <th rowSpan="1">2015</th>
                                            <td className="text-center">8</td>
                                            <td colSpan="3"><span className="color-green">《Angel Stone》</span>&nbsp; 开启技术开发服务
                                            </td>
                                        </tr>
                                        {/* 2014 */}
                                        <tr>
                                            <th rowSpan="2">2014</th>
                                            <td className="text-center">12</td>
                                            <td colSpan="3"><span className="color-green">《C9》</span>&nbsp;国服开启技术开发服务
                                                <a rel="noreferrer" href="http://c9.xiyouxi.com" target="_blank"> ▷ http://c9.xiyouxi.com</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">1</td>
                                            <td colSpan="3"><span className="color-green">《Morinori (妹纸向前冲)》</span>&nbsp;国服开启服务 - 在中国服务的第一款手游
                                            </td>
                                        </tr>
                                        {/* 2013 */}
                                        <tr>
                                            <th rowSpan="1">2013</th>
                                            <td className="text-center">8</td>
                                            <td colSpan="3"><span className="color-green">《Call of Chaos (混沌帝)》</span>&nbsp; 国服开启服务
                                            </td>
                                        </tr>
                                        <tr>
                                            <th rowSpan="1">2012</th>
                                            <td className="text-center">7</td>
                                            <td colSpan="3"><span className="color-green">《路尼亚战记》</span>&nbsp;开启技术开发服务
                                            </td>
                                        </tr>
                                        <tr>
                                            <th rowSpan="1">2011</th>
                                            <td className="text-center">11</td>
                                            <td colSpan="3"><span className="color-green">《路尼亚战记》</span>&nbsp;与喜游戏合作，准备为其提供技术开发服务
                                            </td>
                                        </tr>
                                        <tr>
                                            <th rowSpan="1">2010</th>
                                            <td className="text-center">12</td>
                                            <td colSpan="3"><span className="color-green">《Miracle Technology Ltd.》</span>&nbsp;中国创立
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> {/* ./ row-inner */}

                        </article>
                    </div>
                </section>
            </>
        )
    }
}

export default withTranslation()(HistoryContents)
//export default HistoryContents
