import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';

class HrContents extends React.Component {
    // 클릭하는 것에 on 붙고
    // 메뉴 li의 id와 같은 class명을 가진 tbody가 보인다..
    componentDidMount() {

    }

    render() {
        const { t } = this.props;
        return (
            <>
                <section className="hr-management clearfix">
                    <div className="row-w">
                        <div className="wrap-title">
                            <h1 className="tit serif-en">{t('hr_tit')}
                                <span className="breadscrumb">
                                    <Link to="/">{t('home')}</Link>
                                    <Link to="/career/notice">{t('recruit')}</Link>
                                    <Link to="#" className="on">{t('hr_system')}</Link>
                                </span>
                            </h1>
                            {/* <h2 className="sub-tit"/> */}
                            <h3 className="sub-desc" dangerouslySetInnerHTML={{ __html: t('hr_desc') }} />
                        </div>
                        <article className="hr-qualification">
                            <h4 className="tit-type-lb mt60 mb0">{t('qualification')}</h4>
                            <ul className="list4 hr mt10 clearfix">
                                <li>
                                    <div className="img-wrap">
                                        <img src="http://cdn.qijisoft.com/images/sub/career/hr-challenge-min.png" alt={t('challenge')}/>
                                            <span className="tit">{t('challenge')}</span>
                                            <span className="text" dangerouslySetInnerHTML={{ __html : t('challenge_sub')}}/>
                                    </div>
                                </li>
                                <li>
                                    <div className="img-wrap">
                                        <img src="http://cdn.qijisoft.com/images/sub/career/hr-passion-min.png" alt={t('passion')}/>
                                            <span className="tit">{t('passion')}</span>
                                            <span className="text" dangerouslySetInnerHTML={{ __html : t('passion_sub')}}/>
                                    </div>
                                </li>
                                <li>
                                    <div className="img-wrap">
                                        <img src="http://cdn.qijisoft.com/images/sub/career/hr-communication-min.png" alt={t('communication')}/>
                                            <span className="tit">{t('communication')}</span>
                                            <span className="text" dangerouslySetInnerHTML={{ __html : t('communication_sub')}}/>
                                    </div>
                                </li>
                                <li>
                                    <div className="img-wrap">
                                        <img src="http://cdn.qijisoft.com/images/sub/career/hr-renovation-min.png" alt={t('renovation')}/>
                                            <span className="tit">{t('renovation')}</span>
                                            <span className="text" dangerouslySetInnerHTML={{ __html : t('renovation_sub')}}/>
                                    </div>
                                </li>
                            </ul>
                        </article>
                        <article className="hr-summary">
                            <ul className="list3 hr mt40 clearfix">
                                <li>
                                    <dl>
                                        <dt className="tit-type-lb">评价标准</dt>
                                        <dd>
                                            <ul className="dep2 clearfix">
                                                <li>-目标树立 <span className="fas fa-angle-right"/> 业务管理 <span className="fas fa-angle-right"/> 成果评价</li>
                                                <li>-<span className="color-yr">全年常态</span> 业务管理</li>
                                                <li>-<span className="color-yr">一年一次</span> 定期成果评价</li>
                                            </ul>
                                        </dd>
                                    </dl>
                                </li>
                                <li>
                                    <dl>
                                        <dt className="tit-type-lb">薪资/奖励体系</dt>
                                        <dd>
                                            <ul className="dep2 clearfix">
                                                <li>-按成果给予奖励</li>
                                            </ul>
                                        </dd>
                                    </dl>
                                </li>
                                <li>
                                    <dl>
                                        <dt className="tit-type-lb">福利制度</dt>
                                        <dd>
                                            <ul className="dep2 clearfix">
                                                <li>-<span className="color-yr">红白事</span> 特别休假及红白事的<span className="color-yr">礼金</span>发放</li>
                                                <li>-<span className="color-yr">夏日特别休假</span></li>
                                                <li className="in2">-<span className="color-yr">生日休假</span></li><li>-<span className="color-yr">节日</span> 礼物发放</li>
                                                <li className="in2">-<span className="color-yr">三餐</span> 支援</li><li>-<span className="color-yr">交通费</span> 支援</li>
                                                <li className="in2">-<span className="color-yr">零食角</span></li><li>-<span className="color-yr">每月生日Party</span></li>
                                            </ul>
                                        </dd>
                                    </dl>
                                </li>
                            </ul>
                        </article>
                    </div>
                </section>
            </>
        )
    }
}

export default withTranslation()(HrContents)
//export default HistoryContents
