import React, { useEffect, useState, Fragment } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import {
    API_CARERS_DETAIL
} from '../../api/qijisoft'
function NoticeContents() {
    const { t } = useTranslation();
    const params = useParams();
    const [CarersDetail, setCarersDetail] = useState(null)
    const navigate = useNavigate();
    useEffect(() => {
        const getCarersDetail = async (params) => {
            const id = params.career_id || ''
            try {
                let res = await API_CARERS_DETAIL(id)
                if (res.code === 0) {
                    setCarersDetail(res.data)
                } else {
                    alert(JSON.stringify(res.data))
                    navigate('/career/notice')
                }
            } catch (error) {
                alert('网络错误, 请稍后重试!')
            }
        }
        getCarersDetail(params)
        return () => {

        }
    },[params, navigate])
    if (CarersDetail) {
        return (
            <section className="job-notice detail">
                <div className="row-w">
                    <div className="wrap-title">
                        <h1 className="tit">{t('detail')}
                            <span className="breadscrumb">
                                <Link to="/">{t('home')}</Link>
                                <Link to="/career/notice">{t('recruit')}</Link>
                                <Link to="#" className="on">{t('detail')}</Link>
                            </span>
                        </h1>
                        {/* <h2 className="sub-tit" /> */}
                        <h3 className="sub-desc" dangerouslySetInnerHTML={{ __html: t('datail_desc') }} />
                    </div>

                    <article className="vacancy-detail">
                        <h4 className="tit-type-lb">{t('datail_category')}</h4>
                        <div className="tbl-style mt10" id="">
                            <div className="tbl-row th no-border clearfix">
                                <div className="row row3 th">
                                    {t('team')}
                                </div>
                                <div className="row row3 th">
                                    {t('position')}
                                </div>
                                <div className="row row3 th">
                                    {t('vacancy')}
                                </div>
                            </div>
                            <div className="tbl-row clearfix" id="tblCont">
                                <div className="row row3">
                                    {CarersDetail.team.name}
                                </div>
                                <div className="row row3">
                                    {CarersDetail.position.name}
                                </div>
                                <div className="row row3">
                                    {CarersDetail.vacancy}
                                </div>
                            </div>
                        </div>
                    </article>

                    <article className="vacancy-detail">
                        <h4 className="tit-type-lb">{t('job_desc_qualification')}</h4>
                        <div className="tbl-style mt10" id="">
                            <div className="tbl-row th no-border clearfix">
                                {/* <div className="row row2 th">
                                    {t('job')}
                                </div> */}
                                <div className="row row2 th">
                                    {t('qualification')}
                                </div>
                            </div>
                            {/* ▽▽▽ 运营商创建的区域 Areas created by the operator ▽▽▽ */}
                            <div className="tbl-row clearfix op-txt" id="tblTxt1">
                                {/* <div className="row row2">
                                    <p><strong>{CarersDetail.position.name}</strong></p>
                                </div> */}
                                <div className="row row1">
                                    <p>
                                        <strong>资格条件</strong>
                                        <br />
                                        <span dangerouslySetInnerHTML={{ __html: CarersDetail.detail }} />
                                        <br />
                                        {/* <br /><strong>优待事项</strong>
                                        <br />
                                        <span dangerouslySetInnerHTML={{ __html: CarersDetail.discount_items }} /> */}
                                    </p>
                                </div>
                            </div>
                            <div className="tbl-row clearfix op-txt" id="tblTxt2">
                                <div className="row row1">
                                    <p>
                                        <strong>遴选程序</strong>
                                        <br />• 文件遴选
                                        <br />• 面试
                                        <br />• 最终合格
                                        <br />
                                        <br /><strong>工作环境</strong>
                                        <br />• 工作形式        &nbsp; &nbsp; &nbsp; &nbsp;{CarersDetail.work_situation ? CarersDetail.work_situation : "全职"}
                                        <br />• 工作地点        &nbsp; &nbsp; &nbsp; &nbsp;{CarersDetail.workplace ? CarersDetail.workplace : "上海徐汇区钦州北路1066号74号楼4A"}
                                        <br />
                                        <br /><strong>受理期间及受理方法</strong>
                                        <br />• 受理期间        &nbsp; &nbsp; &nbsp; &nbsp;{CarersDetail.start_date} ~ {CarersDetail.end_date}
                                        <br />• 受理方法        &nbsp; &nbsp; &nbsp; &nbsp;上海奇技网站  <span style={{ color: '#ff7d00' }}> <Link to={`/career/apply/${params.career_id}`}> [ 应聘 ] </Link></span>
                                        <br />
                                        <br /><strong>文件提交</strong>
                                        <br />• 简历           &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; (必要)
                                        <br />• 文件     &nbsp; &nbsp; &nbsp; &nbsp;(仅限该岗位提交)
                                        <br />
                                        <br /><strong>其他注意事项</strong>
                                        <br />• 如果发现虚假内容，招聘可能会被取消。
                                    </p>

                                </div>
                            </div>
                        </div> {/* ./ tbl-style */}

                        <div className="wrap-btn">
                            <Link to={`/career/apply/${params.career_id}`} className="btn btn-apply">
                                {t('apply')}
                            </Link>
                        </div>
                    </article>
                </div>
            </section>
        )
    } else {
        return <></>
    }
}

export default NoticeContents
//export default HistoryContents
