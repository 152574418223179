import React from 'react'
import { NavLink, Link } from 'react-router-dom'

function FooterContents() {
    const openInNewTab = (url) => {
        var win = window.open(url, '_blank');
        win.focus();
    }

    return (
        <>
            <footer id="footer" className="clearfix">
                <div className="row-w clearfix">
                    <div className="pull-left footer-logo">
                        <Link to="/main"></Link>
                        {/*<Link to="/" className="logo">company</Link>*/}
                    </div>
                    <div className="copyright pull-right">
                        <ul id="footerMenu">
                            <li>
                                <NavLink to="/biz/game">产品介绍</NavLink>
                            </li>
                            <li>
                                <NavLink to="/company/intro" >关于我们</NavLink>
                            </li>
                            <li>
                                <NavLink to="/company/proposal">商务合作</NavLink>
                            </li>
                        </ul>

                        <address id="bizInfo" className="ko">
                            上海奇技电脑技术有限公司
                            <br />公司地址：上海市徐汇区钦州北路1066号74号楼4A
                            <br />Address：Room 4A, Building 74, No. 1066 North Qinzhou Road, Xuhui District, Shanghai, China
                        </address>
                        <p>
                            <span style={{ cursor: 'pointer' }} onClick={() => openInNewTab("https://beian.miit.gov.cn")} target="_blank">沪ICP备2020036136号-2</span>
                        </p>
                        <p>
                            © 上海奇技 Technology. All rights reserved.&nbsp;
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )
}


export default FooterContents
