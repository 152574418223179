import React from 'react'
import GameContents from '../components/biz/Game'

class Company extends React.Component {
    componentDidMount() {
       // document.title = HTML_TITLE_PREFIX + ' - Home'

    }
    render() {
        return (
            <div id="sub" className="biz">
                <div id="content" className="clearfix">
                    <GameContents />
                    {/*{
                        (function() {
                            console.log(window.location.pathname);
                            if( window.location.pathname == '/biz/game') {
                                return (<GameContents />)
                            }
                        })()
                    }*/}
                </div>
            </div>
        )
    }
}

export default Company
