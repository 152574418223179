import { qijisoft } from '../utils/request'
import qs from 'qs';

import {
    GET_API_BANNERS,
    GET_API_GAMES,
    GET_API_CARERS,
    GET_API_CARERS_DETAIL,
    POST_API_CARERS_APPLY,
    GET_API_TEAMS,
    GET_API_TEAMS_CAREERS,
    GET_API_CATES_CAREERS,
    POST_API_UPLOAD_FILE,
    POST_API_PROPOSAL,
    GET_API_PROPOSAL_TYPE,
    POST_API_CARERS_MY,
    GET_API_CATES_POSITIONS,
    GET_API_COMPANY_INFO
} from './constant'

export const API_CATES_POSITIONS = () => {
    return qijisoft({
        url: GET_API_CATES_POSITIONS,
        method: 'get'
    })
}
export const API_BANNERS = () => {
    return qijisoft({
        url: GET_API_BANNERS,
        method: 'get'
    })
}
export const API_GAMES = () => {
    return qijisoft({
        url: GET_API_GAMES,
        method: 'get'
    })
}
export const API_TEAMS = () => {
    return qijisoft({
        url: GET_API_TEAMS,
        method: 'get'
    })
}
export const API_COMPANY_INFO = () => {
    return qijisoft({
        url: GET_API_COMPANY_INFO,
        method: 'get'
    })
}
/**
 * API_CARERS 查询职位
 * @param team_id number 团队id
 * @param order string 排序方式, order=recent 查询最新职位
*/
export const API_CARERS = (params) => {
    return qijisoft({
        url: GET_API_CARERS,
        method: 'get',
        params: qs.parse(params)
    })
}
/**
 * API_CARERS_DETAIL 查询职位详情
 * @param career_id number 岗位id
*/
export const API_CARERS_DETAIL = (params) => {
    return qijisoft({
        url: GET_API_CARERS_DETAIL + params,
        method: 'get',
    })
}
/**
 * API_CATES_CAREERS 查询岗位列表
 * @param team_id number 团队id
 * @param position_id number 职位id
 * @param cate_id number 岗位id
*/
export const API_CATES_CAREERS = (params) => {
    return qijisoft({
        url: GET_API_CATES_CAREERS,
        method: 'get',
        params: qs.parse(params)
    })
}
export const API_TEAMS_CAREERS = () => {
    return qijisoft({
        url: GET_API_TEAMS_CAREERS,
        method: 'get',
    })
}
export const API_PROPOSAL_TYPE = () => {
    return qijisoft({
        url: GET_API_PROPOSAL_TYPE,
        method: 'get',
    })
}
/**
 *  API_UPLOAD_FILE
 *  const param = new FormData();
 *  param.append("file", file);
 *  data = param
*/
export const API_UPLOAD_FILE = (data) => {
    return qijisoft({
        url: POST_API_UPLOAD_FILE,
        method: 'post',
        data,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
}
/**
 * API_PROPOSAL 合作
 * @param title string 标题
 * @param company_name string 公司名称
 * @param person_name string 个人姓名
 * @param phone string 联系方式
 * @param email string 内容
 * @param policy number 同意内容
 * @param upload_url string 附件
 * @param type string 类别
*/
export const API_PROPOSAL = (data) => {
    return qijisoft({
        url: POST_API_PROPOSAL,
        method: 'post',
        data: qs.stringify(data),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
}
/**
 * API_PROPOSAL 合作
 * @param career_id number 岗位编号
 * @param name string 姓名
 * @param password string 密码
 * @param password_confirmation string 确认密码
 * @param phone string 联系方式， 长度8 至 11 位
 * @param email string 电子邮箱
 * @param resume_url string 简历
 * @param work_url string 作品
 * @param policy number 同意内容
*/
export const API_CARERS_APPLY = (data) => {
    return qijisoft({
        url: POST_API_CARERS_APPLY,
        method: 'post',
        data: qs.stringify(data),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
}
/**
 * API_CARERS_MY 查询应聘状态
 * @param email string 电子邮箱
 * @param password string 密码
*/
export const API_CARERS_MY = (data) => {
    return qijisoft({
        url: POST_API_CARERS_MY,
        method: 'post',
        data: qs.stringify(data),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
}

