import React from 'react'
import { Outlet } from 'react-router-dom'

import '../asset/css/sub_cn.scss'

class Company extends React.Component {
    componentDidMount() {
       // document.title = HTML_TITLE_PREFIX + ' - Home'

    }
    render() {
        return (
            <div id="sub" className="company">
                <div id="content" className="clearfix">
                <Outlet/>
                </div>
            </div>
        )
    }
}

export default Company
