import axios from "axios";

// 创建axios实例
const createService = baseURL => {
    let service = axios.create({
        baseURL, // api的base_url
        timeout: 20 * 1000 // 超时时间 单位是ms，这里设置了10s的超时时间
    });
    // 添加request拦截器
    service.interceptors.request.use(
        config => {
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );
    // 添加respone拦截器
    service.interceptors.response.use(
        response => {
            //接收到响应数据并成功后的一些共有的处理，关闭loading等
            const res = response.data;
            return res;
        },
        error => {
            console.log(error);
        }
    );
    return service
}
const qijisoft = createService(process.env.NODE_ENV === 'development' ? '/api' : '')
export {
    qijisoft,
}