import React from 'react'
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form'
import { Link, useNavigate } from 'react-router-dom'
import {
    API_CARERS_MY
} from '../../api/qijisoft'
import { validEmail, validPwd, validRequired } from '../../utils/validate'
function MyContents() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // 提交表单
    const onSubmit = async values => {
        const json = JSON.parse(JSON.stringify(values))
        try {
            let res = await API_CARERS_MY(json)
            if (res.code === 0) {
                alert('提交成功！')
                navigate(`/career/Result`, { state: { email: json.email, status: res.data.status } })
            } else {
                alert(JSON.stringify(res.data))
            }
        } catch (error) {
            alert('网络错误，请稍后重试!')
        }
    }
    const composeValidators = (...validators) => value =>
        validators.reduce((error, validator) => error || validator(value), undefined)
    return (
        <>
            <section className="apply clearfix">
                <div className="row-w">
                    <div className="wrap-title">
                        <h1 className="tit serif-en">{t('my_tit')}
                            <span className="breadscrumb">
                                <Link to="/">{t('home')}</Link>
                                <Link to="/career/notice">{t('recruit')}</Link>
                                <Link to="#" className="on">{t('my_application')}</Link>
                            </span>
                        </h1>
                        {/* <h2 className="sub-tit" /> */}
                        <h3 className="sub-desc" dangerouslySetInnerHTML={{ __html: t('my_desc') }} />
                    </div>
                    <article className="my apply-form">
                        <Form onSubmit={onSubmit}
                            validate={values => {
                                console.log(values)
                                const errors = {}
                                return errors
                            }}
                            render={({ handleSubmit, submitting }) => (
                                <form id="formProposal" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
                                    <fieldset>
                                        <legend className="hidden">check my apply</legend>
                                        <div className="tbl-style">
                                            <div className="tbl-row clearfix">
                                                <div className="row2">
                                                    <label htmlFor="email" className="show">{t('email')}</label>
                                                    <Field name="email" validate={composeValidators(validRequired, validEmail)}>
                                                        {({ input, meta }) => (
                                                            <div className="wrap-input">
                                                                <input type="email" {...input} id="email" placeholder={t('form_required')} />
                                                                {
                                                                    meta.error && meta.touched ? <><span className="fa fa-exclamation-circle color-r" />&nbsp;{t('form_wrong_email')}</> :
                                                                        meta.touched ? <><span className="fa fa-check-circle color-green" />&nbsp;{t('form_complete')}</> : <></>
                                                                }
                                                                {/* <span className="input-alert unchk" id="email-error">
                                                                    <span className="fa fa-exclamation-circle color-r" />&nbsp;{t('form_wrong_email')}
                                                                </span>
                                                                <span className="input-alert chk" id="email-success">
                                                                    <span className="fa fa-check-circle color-green" />&nbsp;{t('form_complete')}
                                                                </span> */}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                                <div className="row2">
                                                    <label htmlFor="pw1" className="show"> {t('form_password')}</label>
                                                    <Field name="password" validate={composeValidators(validRequired, validPwd)}>
                                                        {({ input, meta }) => (
                                                            <div className="wrap-input">
                                                                <input type="password" {...input} id="pw1" placeholder={t('form_pw_regulation')} />
                                                                {
                                                                    meta.error && meta.touched ? <><span className="fa fa-exclamation-circle color-r" />&nbsp;{t('form_pw_regulation')}</> :
                                                                        meta.touched ? <><span className="fa fa-check-circle color-green" />&nbsp;{t('form_complete')}</> : <></>
                                                                }
                                                                {/* <span className="input-alert unchk"> <span className="fa fa-exclamation-circle color-r" />&nbsp;{t('form_pw_regulation')}</span>
                                                                <span className="input-alert chk"> <span className="fa fa-check-circle color-green" />&nbsp;{t('form_complete')}</span> */}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wrap-btn">
                                            <button className="btn btn-result" type="submit" disabled={submitting}>{t('chk_my_result')}</button>
                                        </div>
                                    </fieldset>
                                </form>
                            )}></Form>
                    </article>
                </div>
            </section>
            <section className="apply clearfix">
                <div className="row-w">
                    <article className="apply-form">
                        <h4 className="tit-type-lb"> {t('how_to_chk')}</h4>
                        <h5 className="str-desc">
                            {t('chk_way')}
                        </h5>
                        <ul className="list3 my bg mt34 clearfix">
                            <li>
                                <dl>
                                    <dd>
                                        <img src="http://cdn.qijisoft.com/images/sub/career/apply-my-received-min.png" alt={t('review')} />
                                    </dd>
                                    <dt className="mt20" dangerouslySetInnerHTML={{ __html: t('result_review') }} />
                                    <dd className="desc">{t('result_review_sub')}</dd>
                                </dl>
                            </li>
                            <li>
                                <dl>
                                    <dd>
                                        <img src="http://cdn.qijisoft.com/images/sub/career/apply-my-pass-min.png" alt={t('pass')} />
                                    </dd>
                                    <dt className="mt20" dangerouslySetInnerHTML={{ __html: t('result_pass') }} />
                                    <dd className="desc">{t('result_pass_sub')}</dd>
                                </dl>
                            </li>
                            <li>
                                <dl>
                                    <dd>
                                        <img src="http://cdn.qijisoft.com/images/sub/career/apply-my-drop-min.png" alt={t('fail')} />
                                    </dd>
                                    <dt className="mt20" dangerouslySetInnerHTML={{ __html: t('result_fail') }} />
                                    <dd className="desc">{t('result_fail_sub')}</dd>
                                </dl>
                            </li>
                        </ul>
                    </article>
                </div>
            </section>
        </>
    )
}


export default MyContents
//export default HistoryContents
