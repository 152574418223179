import React from 'react'
import MainContents from '../components/main/MainContents'


class Main extends React.Component {
    componentDidMount() {
       // document.title = HTML_TITLE_PREFIX + ' - Home'
    }
    render() {
        return (
            <div id="main">
                <div id="content" className="clearfix main christmas">
                    <MainContents />
                </div>
            </div>
        )
    }
}

export default Main
