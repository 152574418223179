import React from 'react'
import { Link } from 'react-router-dom'

export default function Error() {
  return (
      <section id="notFound">
          <div className="row-w">
              <h1>PAGE NOT FOUND</h1>
              <p>非常抱歉。
                  <br/>
                  您要找的页面<span className="noti-color">已被删除或变更</span>，无法访问。</p>
              {/*<p className="ask">请重新尝试，若问题仍然存在，请联系<a href="/customer/inquiry">客服中心</a>。</p>*/}
              <div className="wrap-btn ">
                  <Link to="/main" className="btn-tbl-form btn">回到首页</Link>
              </div>
          </div>
      </section>
  )
}
