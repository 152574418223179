import React, { useEffect, useState, Fragment } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import {
    API_CATES_CAREERS,
    API_TEAMS,
    API_CATES_POSITIONS
} from '../../api/qijisoft'

export default function DetailContents() {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const cate_id = searchParams.get('cate_id')
    const team_id = searchParams.get('team_id')
    const [Teams, setTeams] = useState([])
    const [CatesCareers, setCatesCareers] = useState([])
    const [CatesPositions, setCatesPositions] = useState([])
    const [Positions, setPositions] = useState([])
    useEffect(() => {
        const getCatesPositions = async () => {
            try {
                let res = await API_CATES_POSITIONS()
                if (res.code === 0) {
                    setCatesPositions(res.data)
                    filterPositions(res.data, cate_id)
                } else {
                    alert(JSON.stringify(res.data))
                }
            } catch (error) {
                alert('网络错误, 请稍后重试!')
            }
        }
        getTeams()
        getCatesPositions()
        if (cate_id) {
            getCatesCareers({ cate_id })
        } else {
            getCatesCareers()
        }
        return () => {

        }
    }, [cate_id])
    // 获取岗位职位列表
    const getCatesCareers = async (params) => {
        try {
            let res = await API_CATES_CAREERS(params)
            if (res.code === 0) {
                setCatesCareers(res.data)
            } else {
                alert(JSON.stringify(res.data))
            }
        } catch (error) {
            alert('网络错误, 请稍后重试!')
        }
    }
    // 获取团队列表
    const getTeams = async () => {
        try {
            let res = await API_TEAMS()
            if (res.code === 0) {
                setTeams(res.data)
            } else {
                alert(JSON.stringify(res.data))
            }
        } catch (error) {
            alert('网络错误, 请稍后重试!')
        }
    }
    // 查询岗位列表
    const changeCate = (value) => {
        if (value === '0') {
            getCatesCareers()
            setSearchParams({})
        } else {
            setSearchParams({ cate_id: value })
            getCatesCareers({ cate_id: value })
        }
        filterPositions(CatesPositions, value)
    }
    // 查询团队列表
    const changeTeam = (value) => {
        if (value === '0') {
            getCatesCareers()
            setSearchParams({})
        } else {
            setSearchParams({ team_id: value })
            getCatesCareers({ team_id: value })
        }
    }
    // 查询职位列表
    const changePosition = (value) => {
        if (value === '0') {
            getCatesCareers({ cate_id })
        } else {
            getCatesCareers({ cate_id, position_id: value })
        }
    }
    // 筛选职位
    const filterPositions = (CatesPositions, val) => {
        let id = val ? (val * 1) : 0
        let cates = CatesPositions.filter(item => item.id === id)
        let positions = []
        if (cates.length) {
            positions = cates[0].positions
        }
        setPositions(positions)
    }
    return (
        <>
            <section className="job-notice">
                <div className="row-w">
                    <div className="wrap-title">
                        <h1 className="tit">{t('notice_tit')}
                            <span className="breadscrumb">
                                <Link to="/">{t('home')}</Link>
                                <Link to="/career/notice">{t('recruit')}</Link>
                                <Link to="#" className="on">{t('job_vacancy')}</Link>
                            </span>
                        </h1>
                        {/* <h2 className="sub-tit" /> */}
                        <h3 className="sub-desc" dangerouslySetInnerHTML={{ __html: t('notice_desc') }} />
                    </div>

                    <article className="vacancy-form">
                        <form>
                            <fieldset>
                                <legend className="hidden">{t('career_search')}</legend>
                                <div className="tbl-style">
                                    <div className="tbl-row career clearfix">
                                        <div className="row3">
                                            <label htmlFor="cateJob" className="show">{t('sel_cate')}</label>
                                            <div className="wrap-input sel">
                                                <div className="sel-ellipsis" id="job"></div>
                                                <select id="cateJob" autoFocus className="job option" value={cate_id ? cate_id : '0'} onChange={(e) => changeCate(e.target.value)}>
                                                    <option value="0">{t('all_cate')}</option>
                                                    {
                                                        CatesPositions.map(item => (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row3">
                                            <label htmlFor="catePosition"
                                                className="show">{t('sel_duty')}</label>
                                            <div className="wrap-input sel">
                                                <div className="sel-ellipsis divPosition"></div>
                                                <select id="catePosition" className="option position" onChange={(e) => changePosition(e.target.value)}>
                                                    <option value="0">{t('all_duty')}</option>
                                                    {
                                                        Positions.map(item => (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row3">
                                            <label htmlFor="cateTeam" className="show">{t('sel_team')}</label>
                                            <div className="wrap-input  sel">
                                                <div className="sel-ellipsis"></div>
                                                <select id="cateTeam" className="option" value={team_id ? team_id : '0'} onChange={(e) => changeTeam(e.target.value)}>
                                                    <option value="0">{t('all_team')}</option>
                                                    {
                                                        Teams.map(item => (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div> {/*END tbl-style*/}
                            </fieldset>
                        </form>
                    </article>
                </div>
            </section>

            <section className="job-list vacancy">
                {
                    !cate_id && !team_id ? CatesCareers.map(items => (
                        <article key={items.id} className="career pt10 mt40">
                            <div className="row-w">
                                <h4 className="tit-type-lb">{items.code}&nbsp;{items.name}</h4>
                                <div className="tbl-style">
                                    <div className="tbl-row th no-border clearfix">
                                        <div className="row4 th">职位</div>
                                        <div className="row4 th">团队</div>
                                        <div className="row4 th">空缺</div>
                                        <div className="row4 th">详情</div>
                                    </div>
                                    <div id="tblCont1">
                                        {
                                            items.careers.length ? items.careers.map(item => (
                                                <div key={item.id} className="tbl-row no-border clearfix">
                                                    <div className="row4 td">{item.position.name}</div>
                                                    <div className="row4 td">{item.team.name}</div>
                                                    <div className="row4 td">{item.vacancy}</div>
                                                    <div className="row4 td">
                                                        <div className="wrap-btn type-pos-ab">
                                                            <Link to={`/career/detail/${item.id}`} className="btn btn-detail">{t('detail')}</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) :
                                                <div className="tbl-row no-border clearfix">
                                                    <div className="row1 td">暂无招聘信息!</div>
                                                </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </article>
                    )) : CatesCareers.map(items => (
                        items.careers.length ? <article key={items.id} className="career pt10 mt40">
                            <div className="row-w">
                                <h4 className="tit-type-lb">{items.code}&nbsp;{items.name}</h4>
                                <div className="tbl-style">
                                    <div className="tbl-row th no-border clearfix">
                                        <div className="row4 th">职位</div>
                                        <div className="row4 th">团队</div>
                                        <div className="row4 th">空缺</div>
                                        <div className="row4 th">详情</div>
                                    </div>
                                    <div id="tblCont1">
                                        {
                                            items.careers.map(item => (
                                                <div key={item.id} className="tbl-row no-border clearfix">
                                                    <div className="row4 td">{item.position.name}</div>
                                                    <div className="row4 td">{item.team.name}</div>
                                                    <div className="row4 td">{item.vacancy}</div>
                                                    <div className="row4 td">
                                                        <div className="wrap-btn type-pos-ab">
                                                            <Link to={`/career/detail/${item.id}`} className="btn btn-detail">{t('detail')}</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>
                            </div>
                        </article> : <Fragment key={items.id} />
                    ))
                }
            </section>

        </>
    )
}

