// Import Swiper React components
import SwiperCore, { EffectFade, Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import '../../asset/css/swiper-custom/pagination.scss';
import '../../asset/css/swiper-custom/swiper-vars.scss';

import './mainBanner.scss'


export default function MainBanner(props){
    const banners = props.banners
    SwiperCore.use([EffectFade, Pagination, Autoplay])
    return (
        <section className="visual">
            <div className="sliderContainer">
                <Swiper
                    effect={'coverflow'}
                    slideToClickedSlide={true}
                    pagination={{
                        clickable: true,
                    }}
                    loop
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                    }}
                    watchSlidesProgress
                    className="mySwiper"
                >
                    {
                        banners.length ? (banners.map(item => {
                            return (
                                <SwiperSlide key={item.id}>
                                    <img src={item.img_url} alt={item.game_code} />
                                </SwiperSlide>
                            )
                        })) : <SwiperSlide></SwiperSlide>
                    }
                </Swiper>

            </div>
        </section>
    );
};