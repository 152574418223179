import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import {
    API_CATES_CAREERS
} from '../../api/qijisoft'
class IntroContents extends React.Component {
    state = {
        CatesCareers: [],
    }
    // 클릭하는 것에 on 붙고
    // 메뉴 li의 id와 같은 class명을 가진 tbody가 보인다..
    componentDidMount() {
        this.getCatesCareers()
    }
    getCatesCareers = async () => {
        try {
            let res = await API_CATES_CAREERS()
            if (res.code === 0) {
                this.setState({
                    CatesCareers: res.data
                })
            } else {
                alert(JSON.stringify(res.data))
            }
        } catch (error) {
            alert('网络错误, 请稍后重试!')
        }
    }
    render() {
        const { t } = this.props;
        const { CatesCareers } = this.state;
        return (
            <>
                <section className="job-introduction">
                    <div className="row-w">
                        <div className="wrap-title">
                            <h1 className="tit serif-en">{t('job_intro_tit')}
                                <span className="breadscrumb">
                                    <Link to="/">{t('home')}</Link>
                                    <Link to="/career/notice">{t('recruit')}</Link>
                                    <Link to="#" className="on">{t('job_intro')}</Link>
                                </span>
                            </h1>
                            {/* <h2 className="sub-tit"/> */}
                            <h3 className="sub-desc" dangerouslySetInnerHTML={{ __html: t('job_intro_desc') }} />
                        </div>

                        {
                            CatesCareers.map(item => {
                                return (
                                    <article key={item.id}>
                                        <h4 className="job-tit mt60">
                                            {ArticleIcon(item.id)}
                                            {item.code}&nbsp;{item.name}</h4>
                                        <ul className="list4 job mt10 clearfix">
                                            {
                                                item.careers.map(child => {
                                                    return (<li key={child.id}><Link to={`/career/detail/${child.id}`}>{child.position.name}</Link></li>)
                                                })
                                            }
                                        </ul>
                                    </article>
                                )
                            })
                        }
                    </div>
                </section>
                <section className="recruit">
                    <div className="row-w">
                        <article className="position">
                            <h1 className="tit-type3">职位</h1>
                            <ul className="clearfix">
                                {
                                    CatesCareers.map(item => {
                                        return (
                                            <li key={item.id}>
                                                <Link to={`/career/notice?cate_id=${item.id}`}>
                                                    <dl className="text">
                                                        <dt>{item.name}</dt>
                                                        <dd>
                                                            {/* <span className="cate-num"> 7 </span>
                                                            {t('ea')} {t('category')} <br /> */}
                                                            {t('career_hiring_on')}
                                                            <span className="vacant-num"> {item.careers_count} {t('case')}</span>
                                                        </dd>
                                                    </dl>
                                                    <div className="img-wrap">
                                                        {
                                                            Jobicon(item.id)
                                                        }
                                                    </div>
                                                    <span className="btn-job-arr"></span>
                                                </Link>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </article>
                    </div>
                </section>
            </>
        )
    }
}
function ArticleIcon(id) {
    let type = id * 1
    switch (type) {
        case 1:
            return (<img src="http://cdn.qijisoft.com/images/sub/career/intro-dev-min.png" alt="icon development" />)
        case 2:
            return (<img src="http://cdn.qijisoft.com/images/sub/career/intro-tech-min.png" alt="icon technology" />)
        case 3:
            return (<img src="http://cdn.qijisoft.com/images/sub/career/intro-plan-min.png" alt="icon planning" />)
        case 4:
            return (<img src="http://cdn.qijisoft.com/images/sub/career/intro-art-min.png" alt="icon art" />)
        case 5:
            return (<img src="http://cdn.qijisoft.com/images/sub/career/intro-art-min.png" alt="icon art" />)
        case 6:
            return (<img src="http://cdn.qijisoft.com/images/sub/career/intro-art-min.png" alt="icon art" />)
        default:
            return (<img src="http://cdn.qijisoft.com/images/sub/career/intro-tech-min.png" alt="icon technology" />)
    }
}
function Jobicon(id) {
    let type = id * 1
    switch (type) {
        case 1:
            return (<img src="http://cdn.qijisoft.com/images/main/icon-job-programmer-min.png" alt="Job Vacancy - Software Engineering" />)
        case 2:
            return (<img src="//cdn.qijisoft.com/miracle/images/main/icon-job-technology.png"
                alt="Job Vacancy - Software Engineering" />)
        case 3:
            return (<img src="//cdn.qijisoft.com/miracle/images/main/icon-job-planning.png"
                alt="Job Vacancy - Software Engineering" />)
        case 4:
            return (<img src="//cdn.qijisoft.com/miracle/images/main/icon-job-art.png"
                alt="Job Vacancy - Software Engineering" />)
        case 5:
            return (<img src="//cdn.qijisoft.com/miracle/images/main/icon-job-design.png"
                alt="Job Vacancy - Software Engineering" />)
        case 6:
            return (<img src="//cdn.qijisoft.com/miracle/images/main/icon-job-administration.png"
                alt="Job Vacancy - Software Engineering" />)
        default:
            return (<img src="//cdn.qijisoft.com/miracle/images/main/icon-job-technology.png"
                alt="Job Vacancy - Software Engineering" />)
    }
}
export default withTranslation()(IntroContents)
//export default HistoryContents
