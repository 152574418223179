import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import { API_GAMES,API_COMPANY_INFO } from '../../api/qijisoft'
class GameContents extends React.Component {
    state = {
        games: [],
        employee_count: 0,
        countPc: 0,
        countMb: 0,
    }
    componentDidMount() {
        this.getGames()
        this.getCompantInfo()
    }
    getCompantInfo = async () => {
        try {
            let res = await API_COMPANY_INFO()
            if(res.code === 0) {
                let employee_count = res.data.employee_count
                let countPc = res.data.game_count.filter(item => item.platform === "PC")
                let countMb = res.data.game_count.filter(item => item.platform === "MOBILE")
                this.setState({
                    employee_count,
                    countPc: countPc.length ? countPc[0].count : 0,
                    countMb: countMb.length ? countMb[0].count : 0,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    getGames = async () => {
        try {
            let res = await API_GAMES()
            if (res.code === 0) {
                this.setState({
                    games: res.data
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        const { t } = this.props;
        const { games } = this.state
        return (
            <>
                <section className="qiji-game clearfix">
                    <div className="row-w">
                        <div className="wrap-title">
                            <h1 className="tit serif-en">{t('game_tit')}
                                <span className="breadscrumb">
                                    <Link to="/">{t('home')}</Link>
                                    <Link to="/biz/game">{t('biz_introduction')}</Link>
                                    <Link to="#" className="on">{t('biz_game')}</Link>
                                </span>
                            </h1>
                            {/* <h2 className="sub-tit" /> */}
                            {/* <h3 className="sub-desc" dangerouslySetInnerHTML={{ __html: t('game_desc') }} /> */}
                            <h3 className="sub-desc" >
                            上海奇技用10多年的技术和经验，<br/>成功开发{this.state.countPc}款网络游戏{this.state.countMb ? `和（${this.state.countMb}）款手游` : ''}。
                            </h3>
                        </div>
                        <article>
                            <ul className="list-1 game mt20">
                                {
                                    games.map(item => {
                                        return (
                                            <li className={`${item.platform} on`} key={item.id}>
                                                <div className="pull-left">
                                                    <a href={item.website_url} target="target">
                                                        <img src={item.img_url} alt={item.name} />
                                                    </a>
                                                </div>
                                                <div className="pull-right">
                                                    <dl>
                                                        <dt>
                                                            {item.name}
                                                            <div className="wrap-device-all">
                                                                <div className="wrap-device desktop">
                                                                    <span className="device-txt">{item.platform}</span>
                                                                    |
                                                                    <span className="genre-txt">{item.game_type}</span>
                                                                </div>
                                                            </div>
                                                        </dt>
                                                        <dd dangerouslySetInnerHTML={{ __html: item.description }} />
                                                            {/* {item.description} */}
                                                        {/* </dd> */}
                                                    </dl>
                                                    <div className="wrap-btn type-txt-l">
                                                        <a href={item.website_url} target="target" className="btn btn-site" rel="stylesheet">
                                                            {t('go_site')}
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </article>
                    </div>
                </section>
            </>
        )
    }
}

export default withTranslation()(GameContents)
//export default HistoryContents
