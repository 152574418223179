import React from "react";
import { Link, NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";

import "../../asset/css/fonts.css";
import "./header.scoped.scss";
import * as fnHeader from "./header";

class HeaderContents extends React.Component {
  constructor(props, context) {
    super(props);

    this.state = {
      is_mobile_header: false,
      selected: "cn",
      accordion_menu_open_focus: [false, false, false],
      accordion_menu_open: [false, false, false],
    };
  }

  toggleMobileHeader() {
    this.setState({ is_mobile_header: !this.state.is_mobile_header });
  }
  handelAccordionMenu(menuNum) {
    const { accordion_menu_open_focus, accordion_menu_open } = this.state;
    const menu_open = accordion_menu_open;
    const menu_open_focus = accordion_menu_open_focus.map(
      (menu, index) => (accordion_menu_open_focus[index] = false)
    );
    menu_open[menuNum] = !menu_open[menuNum];
    menu_open_focus[menuNum] = !menu_open_focus[menuNum];
    this.setState({
      accordion_menu_open: menu_open,
      accordion_menu_open_focus: menu_open_focus,
    });
  }

  changeLanguage = (e) => {
    let lang = e.target.value;

    if (lang !== "cn") {
      window.location.href = "https://www.valofe.com?locale=" + e.target.value;
    }
  };
  /*
    selectLanguage (lang){
        // 버튼 클릭시 ajax 실행
        alert(lang);
        if( lang === 'cn') {
            this.setState.clicked_lang = 'cn';
            window.location.href="/";
        } else {
            this.setState.clicked_lang = lang;
            window.location.href = '//www.valofe.com/language/setlanguage?lang=' + lang;
        }
    }*/
  componentDidMount() {
    fnHeader.toggleHeader();
  }
  render() {
    const { t } = this.props;

    let activeStyle = {
      color: "#ff7d00",
    };
    return (
      <>
        {/* descktopHeader */}
        <div className="skip-navi">
          <Link to="#content">{t("go_content")}</Link>
        </div>
        <div id="util">
          <div className="row-w clearfix">
            &nbsp; <br /> &nbsp;
          </div>
        </div>
        <header id="header">
          <div className="row-w">
            <nav id="gnb" className="desktop-header clearfix">
              <h1 id="logo">
                <Link to="/main">
                  <img
                    src="//cdn.qijisoft.com/miracle/images/logo-white.png"
                    alt="上海奇技 Logo"
                  />
                </Link>
              </h1>
              <ul className="gnb">
                <li>
                  {" "}
                  <NavLink
                    to="/"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    {t("home")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                    to="/company"
                  >
                    {t("intro")}
                  </NavLink>
                  <ul className="sub-menu clearfix">
                    <li>
                      <Link to="/company/intro">{t("intro")}</Link>
                    </li>
                    <li>
                      <Link to="/company/history">{t("history")}</Link>
                    </li>
                    <li>
                      <Link to="/company/proposal">{t("proposal")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                    to="/biz/game"
                  >
                    {t("biz_introduction")}
                  </NavLink>
                  <ul className="sub-menu clearfix">
                    <li>
                      <Link to="/biz/game">{t("biz_game")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                    to="/career"
                  >
                    {t("recruit")}
                  </NavLink>
                  <ul className="sub-menu clearfix">
                    <li>
                      <Link to="/career/hr">{t("hr_system")}</Link>
                    </li>
                    <li>
                      <Link to="/career/intro">{t("job_intro")}</Link>
                    </li>
                    <li>
                      <Link to="/career/notice">{t("job_vacancy")}</Link>
                    </li>
                    <li>
                      <Link to="/career/my">{t("my_application")}</Link>
                    </li>
                  </ul>
                </li>
                {/*<li><Link to="">{t('game_platform')}</Link></li>*/}
              </ul>
            </nav>
          </div>
        </header>

        {/* mobile header - 상단 */}
        <header id="mobileHeader" className="clearfix">
          <div className="row-w clearfix">
            <h1 id="crtMenu">
              {/* <Link to="#" onClick={e => this.goBack(e)} id="back"><span className="fa fa-angle-left" /></Link> */}
              <Link to="/" id="crt">
                Home
              </Link>
            </h1>
            <Link to="/" id="qiji">
              <img
                src="//cdn.qijisoft.com/miracle/images/logo-white-small.png"
                alt="logo"
              />
            </Link>
            <Link
              id="btnMoblieGnb"
              to="#"
              onClick={(e) => this.toggleMobileHeader(e)}
            >
              <span className="fa fa-bars" />
            </Link>
          </div>
        </header>

        {/* mobile  header - gnb menu*/}
        {this.state.is_mobile_header ? (
          <div
            className="shadow mb-gnb"
            onClick={(e) => this.toggleMobileHeader(e)}
          ></div>
        ) : null}
        <div id="mobileGnb" className={this.state.is_mobile_header ? "on" : ""}>
          <div className="side-menu">
            <h1 className="tit-service">
              <Link to="/main">
                <img
                  src="//cdn.qijisoft.com/miracle/images/logo-white.png"
                  className="mb-logo"
                  alt="logo"
                />
              </Link>
              <Link
                to="#"
                className="btn-mb-close"
                onClick={(e) => this.toggleMobileHeader(e)}
              ></Link>
            </h1>

            <div className="nav-list">
              <ul>
                <li className="nav-li">
                  <NavLink to="/">
                    <span className="fa fa-home icon" /> {t("home")}
                  </NavLink>
                </li>
                <li
                  className={
                    this.state.accordion_menu_open[0] === true
                      ? "nav-li on"
                      : "nav-li"
                  }
                  onClick={(menuNum) => this.handelAccordionMenu(0)}
                >
                  <NavLink to="#">
                    <span className="fa fa-building icon" /> {t("intro")}
                    <span className="size">3</span>
                    <span
                      className={
                        this.state.accordion_menu_open[0] === true
                          ? "blit-arrow on"
                          : "blit-arrow"
                      }
                    />
                  </NavLink>
                  {this.state.accordion_menu_open[0] === true ? (
                    <ul className="nav-list2 clearfix">
                      <li>
                        <NavLink
                          to="/company/intro"
                          onClick={(e) => this.toggleMobileHeader(e)}
                        >
                          {" "}
                          {t("intro")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/company/history"
                          onClick={(e) => this.toggleMobileHeader(e)}
                        >
                          {" "}
                          {t("history")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/company/proposal"
                          onClick={(e) => this.toggleMobileHeader(e)}
                        >
                          {" "}
                          {t("proposal")}
                        </NavLink>
                      </li>
                    </ul>
                  ) : null}
                </li>
                <li
                  className={
                    this.state.accordion_menu_open[1] === true
                      ? "nav-li on"
                      : "nav-li"
                  }
                  onClick={(e) => this.handelAccordionMenu(1)}
                >
                  <NavLink to="#">
                    <span className="fa fa-briefcase icon" />{" "}
                    {t("biz_introduction")}
                    <span className="size">1</span>
                    <span
                      className={
                        this.state.accordion_menu_open[1] === true
                          ? "blit-arrow on"
                          : "blit-arrow"
                      }
                    />
                  </NavLink>
                  {this.state.accordion_menu_open[1] === true ? (
                    <ul className="nav-list2 clearfix">
                      <li>
                        <NavLink
                          to="/biz/game"
                          onClick={(e) => this.toggleMobileHeader(e)}
                        >
                          {" "}
                          {t("biz_game")}
                          {/* <span className="size">1</span>
                                                    <span className="blit-arrow"/> */}
                        </NavLink>
                      </li>
                    </ul>
                  ) : null}
                </li>
                <li
                  className={
                    this.state.accordion_menu_open[2] === true
                      ? "nav-li on"
                      : "nav-li"
                  }
                  onClick={(e) => this.handelAccordionMenu(2)}
                >
                  <NavLink to="#">
                    <span className="fa fa-user icon" /> {t("recruit")}
                    <span className="size">4</span>
                    <span
                      className={
                        this.state.accordion_menu_open[2] === true
                          ? "blit-arrow on"
                          : "blit-arrow"
                      }
                    />
                  </NavLink>
                  {this.state.accordion_menu_open[2] === true ? (
                    <ul className="nav-list2 clearfix">
                      <li>
                        <NavLink
                          to="/career/hr"
                          onClick={(e) => this.toggleMobileHeader(e)}
                        >
                          {t("hr_system")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/career/intro"
                          onClick={(e) => this.toggleMobileHeader(e)}
                        >
                          {t("job_intro")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/career/notice"
                          onClick={(e) => this.toggleMobileHeader(e)}
                        >
                          {t("job_vacancy")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/career/my"
                          onClick={(e) => this.toggleMobileHeader(e)}
                        >
                          {t("my_application")}
                        </NavLink>
                      </li>
                    </ul>
                  ) : null}
                </li>
                {/*<li className="nav-li"><Link to="">{t('game_platform')}</Link></li>*/}
              </ul>
            </div>

            <ul id="mb-sel">
              <li className="clearfix">
                <span className="mb-sel-cate pull-left">语言变更</span>
                <select
                  className="mb-sel-lang pull-right"
                  value="cn"
                  onChange={(e) => this.changeLanguage(e)}
                >
                  <option value="cn" defaultValue>
                    中文(简体)
                  </option>
                  <option value="ko">한국어</option>
                  <option value="en">English</option>
                  <option value="jp">日本語</option>
                  <option value="tw">中文(繁体)</option>
                  <option value="vi">Tiếng Việt</option>
                </select>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(HeaderContents);
//export default HeaderContents
