import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
// https://final-form.org/docs/react-final-form
import { Form, Field } from 'react-final-form'
import { validName, validEmail, validPhone, validRequired } from '../../utils/validate'
import {
    API_UPLOAD_FILE,
    API_PROPOSAL,
    API_PROPOSAL_TYPE
} from '../../api/qijisoft'

function ProposalContents() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [file, setfile] = useState({
        filename: '',
        upload_url: ''
    })
    const [options, setoptions] = useState([])

    const getType = async () => {
        try {
            let res = await API_PROPOSAL_TYPE()
            if (res.code === 0) {
                setoptions(res.data)
            } else {
                alert(JSON.stringify(res.data))
            }
        } catch (error) {
            alert('网络错误, 请稍后重试!')
        }
    }
    useEffect(() => {
        getType()
        return () => { }
    },[])
    const ReadFiles = async (file) => {
        let maxSize = 1024 * 1024 * 5
        if (file.target.files[0].size > maxSize) {
            return alert('上传文件不能超过5MB')
        }
        const param = new FormData();
        param.append("file", file.target.files[0]);
        try {
            let filedata = await API_UPLOAD_FILE(param)
            if (filedata.code === 0) {
                setfile({
                    filename: file.target.files[0].name,
                    upload_url: filedata.data.url
                })
            } else {
                alert(JSON.stringify(filedata.data))
            }
        } catch (error) {
            alert('网络错误, 请稍后重试!')
        }
    }
    // 提交表单
    const onSubmit = async values => {
        const { upload_url } = file
        const json = JSON.parse(JSON.stringify(values))
        json.policy = json.policy ? 1 : 0
        if (upload_url) {
            json.upload_url = upload_url
        }
        try {
            let res = await API_PROPOSAL(json)
            if (res.code === 0) {
                alert('提交成功！')
                navigate("/")
            } else {
                alert(JSON.stringify(res.data))
            }
        } catch (error) {
            alert('网络错误，请稍后重试!')
        }
    }
    // 是否选择类别
    const ValidatorsOption = value => {
        if (isNaN(value * 1)) return 'error'
    }
    const ValidatorsMaxlengthTitle = value => {
        if (5 > value.length || value.length > 100) return 'error'
    }
    const ValidatorsMaxlengthCompany = value => {
        if (3 > value.length || value.length > 50) return 'error'
    }
    const ValidatorsMaxlength500 = value => {
        if (value.length > 500) return 'error'
    }
    const composeValidators = (...validators) => value =>
        validators.reduce((error, validator) => error || validator(value), undefined)
    return (
        <>
            <section className="proposal clearfix">
                <div className="row-w">
                    <div className="wrap-title">
                        <h1 className="tit">{t('proposal_tit')}
                            <span className="breadscrumb">
                                <Link to="/">{t('home')}</Link>
                                <Link to="/company/intro">{t('intro')}</Link>
                                <Link to="#" className="on">{t('proposal')}</Link>
                            </span>
                        </h1>
                        {/* <h2 className="sub-tit">{t('proposal_subtit')}</h2> */}
                        {/* <h3 className="sub-desc">{t('proposal_desc')}</h3> */}
                        <h3 className="sub-desc" dangerouslySetInnerHTML={{ __html: t('proposal_desc') }} />
                    </div>

                    <article className="proposal-form">
                        <Form onSubmit={onSubmit}
                            render={({ handleSubmit, submitting }) => (
                                <form id="formProposal" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
                                    <fieldset>
                                        <legend className="hidden">proposal_form</legend>
                                        <div className="tbl-style">
                                            {/* title */}
                                            <div className="tbl-row clearfix">
                                                <Field name="title" validate={composeValidators(validRequired, ValidatorsMaxlengthTitle)}>
                                                    {({ input, meta }) => (
                                                        <div className="row1 clearfix">
                                                            <label htmlFor="title" className="show">{t('form_title')}</label>
                                                            <div className="wrap-input">
                                                                <input type="text" {...input} placeholder={[t('form_required'), t('form_title_error')]} autoFocus />
                                                                {
                                                                    meta.error && meta.touched ? <><span className="fa fa-exclamation-circle color-r" />{t('form_title_error')}</> :
                                                                        meta.touched ? <><span className="fa fa-check-circle color-green" />&nbsp;{t('form_complete')}</> : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                            {/* partner company name */}
                                            <div className="tbl-row clearfix">
                                                <Field name="company_name" validate={composeValidators(validRequired, ValidatorsMaxlengthCompany)}>
                                                    {({ input, meta }) => (
                                                        <div className="row3">
                                                            <label htmlFor="company_name"
                                                                className="show">{t('form_company_name')}</label>
                                                            <div className="wrap-input">
                                                                <input type="text" {...input} id="company_name" placeholder={t('form_required')} />
                                                                {
                                                                    meta.error && meta.touched ? <><span className="fa fa-exclamation-circle color-r" />{t('form_company_error')}</> :
                                                                        meta.touched ? <><span className="fa fa-check-circle color-green" />&nbsp;{t('form_complete')}</> : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                                </Field>
                                                <Field name="person_name" validate={composeValidators(validRequired, validName)}>
                                                    {({ input, meta }) => (
                                                        <div className="row3">
                                                            <label htmlFor="person_name" className="show">{t('form_company_assignee')}</label>
                                                            <div className="wrap-input">
                                                                <input type="text" {...input} id="pName" placeholder={t('form_required')} />
                                                                {
                                                                    meta.error && meta.touched ? <><span className="fa fa-exclamation-circle color-r" />&nbsp;必须是汉字 且最少两个汉字，最多不超过五个</> :
                                                                        meta.touched ? <><span className="fa fa-check-circle color-green" />&nbsp;{t('form_complete')}</> : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                                </Field>
                                                <div className="row3">
                                                    <label htmlFor="cate" className="show">{t('form_category')}</label>
                                                    <div className="wrap-input">
                                                        <Field name="type" component="select" validate={ValidatorsOption}>
                                                            <option>{t('form_required')}</option>
                                                            {
                                                                options.map(item => {
                                                                    return (
                                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Field>
                                                        <Error name="type" t={t} text={t('form_required')} />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* contact */}
                                            <div className="tbl-row clearfix">
                                                <Field name="phone" validate={composeValidators(validRequired, validPhone)}>
                                                    {({ input, meta }) => (
                                                        <div className="row3">
                                                            <label htmlFor="phone"
                                                                className="show">{t('form_contact')}</label>
                                                            <div className="wrap-input">
                                                                <input type="tel" {...input} id="phone" className="int-tel" placeholder={t('form_required')} />
                                                                {
                                                                    meta.error && meta.touched ? <><span className="fa fa-exclamation-circle color-r" />&nbsp;{t('cellphone')} 格式错误</> :
                                                                        meta.touched ? <><span className="fa fa-check-circle color-green" />&nbsp;{t('form_complete')}</> : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                                </Field>
                                                <Field name="email" validate={composeValidators(validRequired, validEmail)}>
                                                    {({ input, meta }) => (
                                                        <div className="row3">
                                                            <label htmlFor="email" className="show">{t('email')}</label>
                                                            <div className="wrap-input">
                                                                <input type="email"{...input} id="email" placeholder={t('form_required')} />
                                                                {
                                                                    meta.error && meta.touched ? <><span className="fa fa-exclamation-circle color-r" />&nbsp;{t('form_wrong_email')}</> :
                                                                        meta.touched ? <><span className="fa fa-check-circle color-green" />&nbsp;{t('form_complete')}</> : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>

                                            {/* content */}
                                            <div className="tbl-row clearfix">
                                                <div className="row1">
                                                    <label htmlFor="content" className="show">{t('form_content')}</label>
                                                    <div className="wrap-input">
                                                        <Field name="content" component="textarea" validate={composeValidators(validRequired, ValidatorsMaxlength500)} placeholder={[t('form_500'), t('form_limit')]} rows="3">
                                                        </Field>
                                                        <Error name="content" t={t} text={t('form_content_error')} />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* file attach */}
                                            <div className="tbl-row clearfix">
                                                <div className="row1">
                                                    <label htmlFor="file"
                                                        className="show">{t('form_file_attach')}</label>
                                                    <div className="fileProposal">
                                                        <input type="file" name="fileProposal" id="fileProposal" className="inputfile inputfile-2" onChange={ReadFiles} accept=".pdf, .hwp, .docx, .pptx, .xlsx" />
                                                        <label htmlFor="fileProposal" className="file-name-label">
                                                            <strong>
                                                                <span className="fa fa-upload" />&nbsp;
                                                                <span className="text">{t('file')}</span>
                                                            </strong>
                                                            <span className="file-name" id="file-name">{file.upload_url !== '' ? file.filename : `*(-)5MB. ${t('pdf_docx_hwp')}`}</span>
                                                        </label>
                                                        <span className="input-alert unchk" id="file-error">
                                                            <span className="fa fa-exclamation-circle color-r" />&nbsp;{t('form_file_format')}
                                                        </span>
                                                        <span className="input-alert chk" id="file-success">
                                                            <span className="fa fa-check-circle color-green" />&nbsp;{t('form_complete')}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        { /*START tbl-style - 개인정보수집동의 */}
                                        <div className="tbl-style">
                                            <div className="tbl-row agree clearfix">
                                                <div className="row0 clearfix">
                                                    <span className="show">{t('proposal_term_txt1')}</span>
                                                    <div className="wrap-input agree">
                                                        {/* <input type="checkbox" {...input} name="privacyChk" id="privacyChk" /> */}
                                                        <label className="right">
                                                            <Field name="policy" component="input" type="checkbox" validate={validRequired} />
                                                            <span>{t('proposal_term_txt2')} </span>
                                                        </label>
                                                        <br />
                                                        <Error name="policy" t={t} text={t('proposal_term_txt4')} />
                                                    </div>
                                                </div>
                                                <div className="row0 clearfix">
                                                    <div className="wrap-input-txt clearfix">
                                                        {/* textarea 안에는 dangerouslySetInnerHTML을 사용할 수 없음... lang을 나눌수도 없음 하나의 child만 가진다고 함 */}
                                                        <div className="textarea privacy-collect" dangerouslySetInnerHTML={{ __html: t('proposal_term_txt3') }} /> {/*contentEditable='true'*/}
                                                        <span className="input-alert unchk" id="privacyChk-error">
                                                            <span className="fa fa-exclamation-circle color-r" /> {t('proposal_term_txt4')}
                                                        </span>
                                                        <span className="input-alert chk" id="privacyChk-success">
                                                            <span className="fa fa-check-circle color-green" />&nbsp;{t('form_complete')}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="wrap-btn tbl">
                                            <button className="btn-tbl-form btn" onClick={() => window.history.go(-1)}>{t('cancel')}</button>
                                            <button className="btn-tbl-form btn" type="submit" disabled={submitting}>{t('confirm')}</button>
                                        </div>
                                    </fieldset>
                                </form>
                            )}>
                        </Form>

                        {/* 제휴신청 입력폼 */}
                    </article>
                </div>
            </section>
        </>
    )
}


const Error = ({ name, t, text }) => (<Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) => (
        error && touched ? <><span className="fa fa-exclamation-circle color-r" />&nbsp;{text}</> :
            touched ? <><span className="fa fa-check-circle color-green" />&nbsp;{t('form_complete')}</> : <></>
    )
    }
/>)

export default ProposalContents
//export default ProposalContents
