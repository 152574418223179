import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { PageView, initGA } from './components/Tracking'
import ReactGA from 'react-ga' //GA1
import { HeadProvider, Link, Title } from "react-head";

import Main from './routes/Main'
import Company from './routes/Company'
import Biz from './routes/Biz'
import Career from './routes/Career'
import Header from './components/common/HeaderContents'
import Footer from './components/common/FooterContents'
import NotFound from './routes/404'

import HrContents from './components/Career/Hr'
import IntroContents from './components/Career/Intro'
import NoticeContents from './components/Career/Notice'
import DetailContents from './components/Career/Detail'
import ApplyContents from './components/Career/Apply'
import MyContents from './components/Career/My'
import ResultContents from './components/Career/Result'

import IntroCompany from './components/company/Intro'
import HistoryCompany from './components/company/History'
import ProposalCompany from './components/company/Proposal'

import './App.scss'
import './asset/css/font-awesome.4.6.2-custom.css'
import './asset/css/common.css'


//const TRACKING_ID = 'UA-45196362-100' //GA2
//ReactGA.initialize(TRACKING_ID) //GA3
//ReactGA.pageview(window.location.pathname + window.location.search);initGA에 포함됨

class App extends React.Component {
    // static contextType = InfoContext
    constructor(props, context) {
        super(props)
        this.state = {
            isLoading: false,       //true일 때 밑에 null로 출력되어서 임시(?) 변경
        }
    }
    componentDidMount() {
        initGA('UA-45196362-108')
        PageView() // 페이지 뷰가 다른 이벤트 추척보다 선행되어야 함
        //ReactGA.event({
        //    category: 'User',
        //    action: 'Created an Account',
        //   label: 'VFUN KR 회원 가입',
        //})
        ReactGA.exception({
            description: 'An error ocurred', //에러 페이지 추적
            fatal: true,
            label: 'Miracle technology Error occuerd!',
        })
    }

    render() {
        return this.state.isLoading === false ? (
            // return (
            <>
                <BrowserRouter>
                    <Header />
                    <HeadProvider>
                        <Routes>
                            <Route path="/" exact={true} component={Main} element={<>
                                <Title>奇技游戏在线</Title>
                                <Link rel="canonical" content={"http://qijisoft.com" + window.location.pathname} />
                                <Main />
                            </>}
                            />
                            <Route path="/main" exact={true} component={Main} element={<>
                                <Title>奇技游戏在线</Title>
                                <Link rel="canonical" content={"http://qijisoft.com" + window.location.pathname} />
                                <Main />
                            </>}
                            />
                            <Route path="company" element={<Navigate to={"/company/intro"} />} />
                            <Route path="company" element={<Company />} >
                                <Route path="intro" element={<>
                                    <Title>公司介绍 - 奇技游戏在线</Title>
                                    <Link rel="canonical" content={"http://qijisoft.com" + window.location.pathname} />
                                    <IntroCompany />
                                </>}
                                />
                                <Route path="history" element={<>
                                    <Title>发展历程 - 奇技游戏在线</Title>
                                    <Link rel="canonical" content={"http://qijisoft.com" + window.location.pathname} />
                                    <HistoryCompany />
                                </>}
                                />
                                <Route path="proposal" element={<>
                                    <Title>商务合作 - 奇技游戏在线</Title>
                                    <Link rel="canonical" content={"http://qijisoft.com" + window.location.pathname} />
                                    <ProposalCompany />
                                </>}
                                />
                            </Route>

                            <Route path="/biz/game" exact={true} component={Biz} element={<>
                                <Title>游戏业务 - 奇技游戏在线</Title>
                                <Link rel="canonical" content={"http://qijisoft.com" + window.location.pathname} />
                                <Biz />
                            </>}
                            />
                            <Route path="career" element={<Navigate to={"/career/hr"} />} />
                            <Route path="career" element={<Career />} >
                                <Route path="hr" element={<>
                                    <Title>人事制度 - 奇技游戏在线</Title>
                                    <Link rel="canonical" content={"http://qijisoft.com" + window.location.pathname} />
                                    <HrContents />
                                </>}
                                />
                                <Route path="intro" element={<>
                                    <Title>职务介绍 - 奇技游戏在线</Title>
                                    <Link rel="canonical" content={"http://qijisoft.com" + window.location.pathname} />
                                    <IntroContents />
                                </>
                                }
                                />
                                <Route path="notice" element={<>
                                    <Title>招聘公告 - 奇技游戏在线</Title>
                                    <Link rel="canonical" content={"http://qijisoft.com" + window.location.pathname} />
                                    <NoticeContents />
                                </>}
                                />
                                <Route path="detail/:career_id" element={
                                    <>
                                        <Title>职位详情 - 奇技游戏在线</Title>
                                        <Link rel="canonical" content={"http://qijisoft.com" + window.location.pathname} />
                                        <DetailContents />
                                    </>
                                } />
                                <Route path="apply/:career_id" element={
                                    <>
                                        <Title>应聘职位 - 奇技游戏在线</Title>
                                        <Link rel="canonical" content={"http://qijisoft.com" + window.location.pathname} />
                                        <ApplyContents />
                                    </>
                                } />
                                <Route path="my" element={<>
                                    <Title>我的简历现状 - 奇技游戏在线</Title>
                                    <Link rel="canonical" content={"http://qijisoft.com" + window.location.pathname} />
                                    <MyContents />
                                </>}
                                />
                                <Route path="result" element={<>
                                    <Title>确认结果 - 奇技游戏在线</Title>
                                    <Link rel="canonical" content={"http://qijisoft.com" + window.location.pathname} />
                                    <ResultContents />
                                </>}
                                />
                                <Route path="*" element={<>
                                    <Title>404 Page Not Found - 奇技游戏在线</Title>
                                    <Link rel="canonical" content={"http://qijisoft.com" + window.location.pathname} />
                                    <NotFound />
                                </>}
                                />
                            </Route>
                            <Route path="*" element={<>
                                <Title>404 Page Not Found - 奇技游戏在线</Title>
                                <Link rel="canonical" content={"http://qijisoft.com" + window.location.pathname} />
                                <NotFound />
                            </>}
                            />
                        </Routes>
                    </HeadProvider>
                    <Footer />
                </BrowserRouter>
            </>
            //)
        ) : null
    }
}

export default App
