import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import * as subFn from '../../asset/js/sub'
import {API_COMPANY_INFO} from '../../api/qijisoft'
class IntroContents extends React.Component {
    state = {
        employee_count: 0,
        countPc: 0,
        countMb: 0,
    }
    componentDidMount() {
        this.getCompantInfo()
    }
    getCompantInfo = async () => {
        try {
            let res = await API_COMPANY_INFO()
            if(res.code === 0) {
                let employee_count = res.data.employee_count
                let countPc = res.data.game_count.filter(item => item.platform === "PC")
                let countMb = res.data.game_count.filter(item => item.platform === "MOBILE")
                this.setState({
                    employee_count,
                    countPc: countPc.length ? countPc[0].count : 0,
                    countMb: countMb.length ? countMb[0].count : 0,
                })
                subFn.countAni();
            }
        } catch (error) {
            console.log(error)
        }
    }
    render() {
        const { t } = this.props;
        return (
            <>
                <section className="miracle-is clearfix">
                    <div className="row-w">
                        <div className="wrap-title">
                            <h1 className="tit serif-en"> {t('intro_tit')}
                                <span className="breadscrumb">
                                <Link to="/">{t('home')}</Link>
                                <Link to="/company/intro">{t('intro')}</Link>
                                <Link to="#" className="on">{t('intro')}</Link>
                            </span>
                            </h1>
                            {/* <h2 className="sub-tit">{t('intro_subtit')}</h2> */}
                            {/* <h3 className="sub-desc">{t('intro_desc')}</h3> */}
                            <h3 className="sub-desc" dangerouslySetInnerHTML={{ __html: t('intro_desc') }} />
                        </div>
                        <article className="miracle-desc">
                            <div className="row-inner">
                                <ul className="list3 miracle bg mt40 clearfix">
                                    <li>
                                        <div className="img-wrap">
                                            <img src="//cdn.qijisoft.com/miracle/images/sub/company/intro/intro-val-emp.png" alt="employee"/>
                                            <span className="num counter" data-count={this.state.employee_count}></span>
                                        </div>
                                        <dl>
                                            <dt>
                                                {t('company_intro_sec1tit1')}
                                            </dt>
                                            <dd dangerouslySetInnerHTML={{ __html : t('company_intro_sec1subtit1')}}>

                                            </dd>
                                        </dl>
                                    </li>
                                    <li>
                                        <div className="img-wrap">
                                            <img src="//cdn.qijisoft.com/miracle/images/sub/company/intro/intro-val-dev.png" alt="pc online development"/>
                                            <span className="num counter" data-count={this.state.countPc}></span>
                                        </div>
                                        <dl>
                                            <dt id="employee">
                                                ({t('company_intro_sec1tit2')})
                                            </dt>
                                            <dd dangerouslySetInnerHTML={{ __html : t('company_intro_sec1subtit2')}} >
                                            </dd>
                                        </dl>
                                    </li>
                                    <li>
                                        <div className="img-wrap">
                                            <img src="//cdn.qijisoft.com/miracle/images/sub/company/intro/intro-val-mb.png" alt="mobile development"/>
                                            <span className="num counter" data-count={this.state.countMb}></span>
                                        </div>
                                        <dl>
                                            <dt dangerouslySetInnerHTML={{ __html : t('company_intro_sec1tit3') }}>
                                            </dt>
                                            <dd>
                                            </dd>
                                        </dl>
                                    </li>
                                </ul>
                            </div>

                        </article>
                    </div>
                </section>

                <section className="company-info clearfix">
                    <div className="row-w">
                        <div className="wrap-title">
                            <h1 className="tit">{t('info_tit')}</h1>
                        </div>
                        <article>
                            <div className="row-inner">
                                <table className="basic">
                                    <colgroup>
                                        <col width="180px"/>
                                        <col width="*"/>
                                    </colgroup>
                                    <tbody>
                                    <tr>
                                        <th><span className="tb-tit name"><img src="//cdn.qijisoft.com/miracle/images/sub/company/intro/icon-company.png" alt="icon company"/>{t('info_name')}</span></th>
                                        <td><span>&nbsp;{t('info_name_cont')}</span></td>
                                    </tr>
                                    <tr>
                                        <th><span className="tb-tit address"><img src="//cdn.qijisoft.com/miracle/images/sub/company/intro/icon-place.png" alt="icon address"/>{t('info_address')}</span></th>
                                        <td>{t('info_address_cont')}</td>
                                    </tr>
                                    <tr>
                                        <th><span className="tb-tit fax"><img src="//cdn.qijisoft.com/miracle/images/sub/company/intro/icon-fax.png" alt="icon fax"/>{t('info_fax')}</span></th>
                                        <td>{t('info_fax_cont')}</td>
                                    </tr>
                                    </tbody>
                                </table>

                                <ul className="list3">
                                    <li><img src="//cdn.qijisoft.com/miracle/images/sub/company/intro/info1.jpg" alt="company panorama"/></li>
                                    <li><img src="//cdn.qijisoft.com/miracle/images/sub/company/intro/qiji-No1.jpg" alt="prize"/></li>
                                    <li><img src="//cdn.qijisoft.com/miracle/images/sub/company/intro/info3.jpg" alt="company map"/></li>
                                </ul>
                            </div>
                        </article>
                    </div>
                </section>
            </>
        )
    }
}

export default withTranslation()(IntroContents)
//export default IntroContents
