import React from 'react'
import { Outlet } from 'react-router-dom'

class Company extends React.Component {
    componentDidMount() {
        // document.title = HTML_TITLE_PREFIX + ' - Home'

    }
    render() {
        console.log(this.props)
        return (
            <div id="sub" className="career">
                <div id="content" className="clearfix">
                    <Outlet/>
                </div>
            </div>
        )
    }
}

export default Company
