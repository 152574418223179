import React from 'react';
import ReactDOM from 'react-dom';
/*import { renderToString } from 'react-dom/server';*/
import { HeadProvider } from 'react-head';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './lang/i18n';


const headTags = [];
ReactDOM.render(
    <HeadProvider headTags={headTags}>
    <App />
    </HeadProvider>,

    document.getElementById('wrapper')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
